/* eslint-disable object-curly-newline */
import styled from '@emotion/styled';
import { NetgreenCapitalLogo as Logo } from '@netgreen/assets';
import { signOut } from '@netgreen/auth';
import { ButtonWithAnalytics, breakpoints } from '@netgreen/core-ui';
import { useLocation } from '@reach/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  IconButton,
  Image,
  Link,
  MenuIcon,
  Pane,
  SideSheet,
  toaster,
} from 'evergreen-ui';
import { Link as GatsbyLink, navigate } from 'gatsby';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { AppRoutes, DashboardRoutes } from '../../constants';
import { LinkConfig } from '../../models';
import { useIsLoggedIn } from '../auth';
import { DashboardNavSection } from './DashboardNavSection';
import { DropdownButton } from './DropdownNavButton';
import { ProfileDropdownButton } from './ProfileDropdownButton';
import { ProfileRow } from './ProfileRow';

const linksConfig: LinkConfig[] = [
  {
    name: 'Marketplace',
    to: `/${AppRoutes.marketplace}`,
  },
  {
    name: 'Farm Stand',
    to: '/farmstand',
  },
  {
    name: 'Community',
    to: '/community',
  },
  {
    name: 'Resources',
    type: 'menu',
    to: '',
    items: [
      {
        to: '/apply',
        name: 'Apply for Fundraising',
      },
      {
        to: '/education',
        name: 'Education Materials',
      },
      {
        to: '/legal/fees',
        name: 'Platform Fee',
      },
      {
        to: '/resources',
        name: 'News & Podcasts',
      },
      {
        to: '/faq',
        name: 'FAQs',
      },
    ],
  },
  {
    name: 'About us',
    to: '/about',
  },
];

const dashboardLinksConfig: LinkConfig[] = [
  {
    name: 'Profile',
    to: `/${AppRoutes.dashboard}/${DashboardRoutes.profile}`,
  },
  {
    name: 'Accounts',
    to: `/${AppRoutes.dashboard}/${DashboardRoutes.accounts}`,
  },
  {
    name: 'Portfolio',
    to: `/${AppRoutes.dashboard}/${DashboardRoutes.portfolio}`,
  },
];

const Divider = styled.hr`
  width: 100%;
`;
const DesktopNav = styled(Pane)`
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    display: inherit;
  }
`;

const LoginButtonsContainer = styled(Pane)`
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: inherit;
  }
`;

const NavbarContainer = styled(Pane)`
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.25);
  padding: 1rem 1.5rem 1rem 1.5rem;
  align-items: center;
  background-color: ${(props) => props.theme.background};
`;

const MenuButtonContainer = styled.div`
  margin-left: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

export const Navbar = () => {
  const [isShown, setIsShown] = useState(false);
  const { isLoggedIn } = useIsLoggedIn();
  const location = useLocation();
  const queryClient = useQueryClient();

  const isDashboardRoute =
    location.pathname.includes('/dashboard/') ||
    location.pathname.endsWith('/dashboard');

  const { mutate: signOutMutation, isLoading: isLoadingLogout } = useMutation(
    signOut,
    {
      onSuccess: () => {
        queryClient.removeQueries({ queryKey: ['current-user'] });

        navigate(`/${AppRoutes.login}`);
        setIsShown(false);
      },
      onError: () => {
        toaster.danger('Logout error', {
          description: 'An error occurred while logging out. Please try again.',
        });
      },
    },
  );

  const logout = useCallback(() => {
    signOutMutation();
  }, [signOutMutation]);

  return (
    <NavbarContainer>
      <Link border="none" is={GatsbyLink} to="/">
        <Image src={Logo} height="3.125rem" marginRight="3rem" />
      </Link>
      <DesktopNav>
        {linksConfig.map((link) => {
          if (link.type !== 'menu') {
            return (
              <Link
                key={link.name}
                is={GatsbyLink}
                to={link.to}
                marginRight={16}
              >
                {link.name}
              </Link>
            );
          }

          if (link.type === 'menu') {
            return <DropdownButton key={link.name} items={link.items ?? []} />;
          }

          return undefined;
        })}
      </DesktopNav>

      <LoginButtonsContainer>
        {!isLoggedIn && (
          <>
            <ButtonWithAnalytics
              analyticName="loginBtn"
              appearance="minimal"
              onClick={() => navigate(`/${AppRoutes.login}`)}
              marginRight="0.5rem"
            >
              Log in
            </ButtonWithAnalytics>

            <ButtonWithAnalytics
              analyticName="signUpBtn"
              appearance="primary"
              onClick={() => navigate(`/${AppRoutes.signUp}`)}
            >
              Sign up
            </ButtonWithAnalytics>
          </>
        )}
        {isLoggedIn && (
          <Pane>
            <ProfileDropdownButton logout={logout} />
          </Pane>
        )}
      </LoginButtonsContainer>

      <MenuButtonContainer>
        <IconButton
          icon={MenuIcon}
          onClick={() => setIsShown(true)}
          border="none"
        />
      </MenuButtonContainer>

      <SideSheet
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        width={300}
      >
        <Pane
          display="flex"
          flexDirection="column"
          height="100%"
          marginX="1rem"
          paddingY="1rem"
        >
          <Pane display="flex" marginBottom="0.25rem">
            <Image src={Logo} height="2.125rem" />
          </Pane>
          <Divider />
          {isLoggedIn && (
            <Pane marginBottom="2rem">
              <ProfileRow showNav={setIsShown} />
            </Pane>
          )}
          {isDashboardRoute && (
            <DashboardNavSection
              linksConfig={dashboardLinksConfig}
              onLinkClick={() => setIsShown(false)}
            />
          )}
          {linksConfig.map((link) => {
            if (link.type !== 'menu') {
              return (
                <Link
                  key={link.name}
                  is={GatsbyLink}
                  to={link.to}
                  paddingY="0.75rem"
                  fontSize="1.25rem"
                  onClick={() => setIsShown(false)}
                >
                  {link.name}
                </Link>
              );
            }

            if (link.type === 'menu') {
              return link.items?.map((subLink) => (
                <Link
                  key={subLink.name}
                  is={GatsbyLink}
                  to={subLink.to}
                  paddingY="0.75rem"
                  fontSize="1.25rem"
                  onClick={() => setIsShown(false)}
                >
                  {subLink.name}
                </Link>
              ));
            }

            return undefined;
          })}
          <Pane
            display="flex"
            marginTop="auto"
            marginBottom="1rem"
            flexDirection="column"
            paddingBottom="4rem"
          >
            {!isLoggedIn && (
              <>
                <ButtonWithAnalytics
                  analyticName="loginBtn"
                  appearance="default"
                  marginBottom="1rem"
                  onClick={() => {
                    navigate(`/${AppRoutes.login}`);
                    setIsShown(false);
                  }}
                >
                  Log in
                </ButtonWithAnalytics>
                <ButtonWithAnalytics
                  analyticName="signUpBtn"
                  appearance="primary"
                  onClick={() => {
                    navigate(`/${AppRoutes.signUp}`);
                    setIsShown(false);
                  }}
                >
                  Sign up
                </ButtonWithAnalytics>
              </>
            )}

            {isLoggedIn && (
              <>
                <hr />
                <ButtonWithAnalytics
                  analyticName="logoutBtn"
                  appearance="minimal"
                  marginBottom="1rem"
                  onClick={logout}
                  isLoading={isLoadingLogout}
                  disabled={isLoadingLogout}
                >
                  Log out
                </ButtonWithAnalytics>
              </>
            )}
          </Pane>
        </Pane>
      </SideSheet>
    </NavbarContainer>
  );
};
