import styled from '@emotion/styled';
import { Pane } from 'evergreen-ui';
import React, { ReactNode } from 'react';
import { Footer } from '../footer';
import { Navbar } from '../navbar';

interface BasePageProps {
  children: ReactNode;
}

const Header = styled.header`
  grid-area: header;
`;

const Main = styled.main`
  grid-area: main;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

const MainFooter = styled.footer`
  grid-area: 2 / 1 / 2 / 4;
  background-color: green;
`;

const Content = styled.section`
  min-height: 90vh;

  #gatsby-focus-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const BasePage = ({ children }: BasePageProps) => (
  <Pane
    height="100vh"
    display="grid"
    gridTemplateColumns="1fr"
    gridTemplateRows="auto 1fr"
    // eslint-disable-next-line @typescript-eslint/quotes
    gridTemplateAreas={`'header' 'main'`}
  >
    <Header>
      <Navbar />
    </Header>
    <Main>
      <Content>{children}</Content>
      <MainFooter>
        <Footer />
      </MainFooter>
    </Main>
  </Pane>
);
