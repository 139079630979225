import axios from 'axios';
import { getTradeClient } from '../client';
import {
  CreateTradeParams,
  Offering,
  Trade,
  TradeMetadata,
  TradeOffering,
} from '../models';
import { getOffering } from './offeringApi';

const baseUrl = `${process.env['GATSBY_API_BASE_URL']}/trade`;

export const createTrade = async ({
  offeringId,
  accountId,
  transactionUnits,
  otherRegCF,
}: CreateTradeParams): Promise<Trade> => {
  const client = getTradeClient(baseUrl);
  try {
    return await client.createTrade(
      offeringId,
      accountId,
      transactionUnits,
      otherRegCF,
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as any).error) {
        throw new Error((err.response?.data as any).error);
      }
    }
    throw err;
  }
};

export const getTrade = async (tradeId: string): Promise<Trade> => {
  const client = getTradeClient(baseUrl);
  return client.getTrade(tradeId);
};

export const cancelTrade = async (tradeId: string): Promise<void> => {
  const client = getTradeClient(baseUrl);
  await client.cancelTrade(tradeId);
};

export const getTradeOfferingMetadata = async (
  offeringId: string,
): Promise<TradeMetadata> => {
  if (!offeringId) {
    throw new Error('no offering id');
  }
  const client = getTradeClient(baseUrl);
  return client.getTradeOfferingMetadata(offeringId);
};

export const getUserTrades = async (): Promise<TradeOffering[]> => {
  const client = getTradeClient(baseUrl);
  const trades = await client.getUserTrades();

  const details: TradeOffering[] = [];
  const offeringsToLookup = [
    ...new Set(trades.map((trade) => trade.offeringId)),
  ];
  const offerings: (
    | PromiseFulfilledResult<Offering>
    | PromiseRejectedResult
  )[] = await Promise.allSettled(
    offeringsToLookup.map((id) => getOffering(id)),
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const trade of trades) {
    const offeringList = offerings
      .filter((prom) => prom.status === 'fulfilled')
      .map((prom) => (prom as PromiseFulfilledResult<Offering>).value)
      .filter((offering) => String(offering.id) === trade.offeringId);
    if (offeringList.length) {
      details.push({
        trade,
        offering: offeringList[0],
      });
    }
  }
  return details;
};
