import { BaseClient } from '../../base-client';
import {
  AddAccountResponse,
  AddExternalAccountResponse,
  CreateAccountRequest,
  CreateExternalAccountRequest,
  DeepPartial,
  GetAccountResponse,
  LinkExternalAccountRequest,
  LinkExternalAccountResponse,
  User,
} from '../models';

interface IUserClient {
  getUser: () => Promise<User>;
  updateUser: (id: string, properties: DeepPartial<User>) => Promise<User>;
  addAccount: (properties: CreateAccountRequest) => Promise<AddAccountResponse>;
  getAccounts: () => Promise<GetAccountResponse[]>;
  linkExternalAccount: (
    properties: LinkExternalAccountRequest,
  ) => Promise<LinkExternalAccountResponse>;
}

class UserClient extends BaseClient implements IUserClient {
  constructor(private baseUrl: string) {
    super(baseUrl);
  }

  async getUser(): Promise<User> {
    const authHeaders = await this.getAuthHeaders();
    const user = await this.getCognitoUser();
    const response = (
      await this.httpClient.get(`/v1/user/${user?.sub}`, {
        headers: { ...authHeaders },
      })
    ).data;

    return { ...response, phoneNumber: user?.phone_number };
  }

  async updateUser(id: string, properties: DeepPartial<User>): Promise<User> {
    const authHeaders = await this.getAuthHeaders();

    return (
      await this.httpClient.post(
        `/v1/user/${id}`,
        { id, ...properties },
        {
          headers: {
            ...authHeaders,
          },
        },
      )
    ).data;
  }

  async addAccount(
    properties: CreateAccountRequest,
  ): Promise<AddAccountResponse> {
    const authHeaders = await this.getAuthHeaders();
    const user = await this.getUser();

    return (
      await this.httpClient.post(
        `v1/user/${user.id}/accounts`,
        { ...properties },
        {
          headers: {
            ...authHeaders,
          },
        },
      )
    ).data;
  }

  async linkExternalAccount(
    properties: LinkExternalAccountRequest,
  ): Promise<LinkExternalAccountResponse> {
    const authHeaders = await this.getAuthHeaders();
    const user = await this.getUser();

    const query = Object.keys(properties)
      .map((key: string) => {
        const v = String(properties[key as keyof LinkExternalAccountRequest]);
        return `${key}=${v}`;
      })
      .join('&');
    return (
      await this.httpClient.get(
        `v1/user/${user.id}/external-account-link?${query}`,
        {
          headers: {
            ...authHeaders,
          },
        },
      )
    ).data;
  }

  async getAccounts(): Promise<GetAccountResponse[]> {
    const authHeaders = await this.getAuthHeaders();
    const user = await this.getUser();

    return (
      await this.httpClient.get(`v1/user/${user.id}/accounts`, {
        headers: {
          ...authHeaders,
        },
      })
    ).data;
  }

  async addExternalAccount(
    properties: CreateExternalAccountRequest,
  ): Promise<AddExternalAccountResponse> {
    const authHeaders = await this.getAuthHeaders();
    const user = await this.getUser();

    return (
      await this.httpClient.post(
        `v1/user/${user.id}/accounts/${properties.accountId}`,
        { ...properties.properties },
        {
          headers: {
            ...authHeaders,
          },
        },
      )
    ).data;
  }
}

export const getUserClient = (baseUrl: string) => new UserClient(baseUrl);
