/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Alert, Heading, Pane } from 'evergreen-ui';
import React, { useCallback, useState } from 'react';
import { User, updateUser } from '@netgreen/clients';
import { getFormattedDob } from '../../models';
import { ProfileForm, ProfileFormModel } from './profileForm';

interface AccountProfileSetupProps {
  user: User | undefined;
  onNext: () => void;
}

export const AccountProfileSetup = ({
  user,
  onNext,
}: AccountProfileSetupProps) => {
  const [formModel, setFormModel] = useState<ProfileFormModel | undefined>();
  const [error, setError] = useState<Error | null>(null);

  const onProfileFormModelChange = useCallback(
    (pformModel: ProfileFormModel) => {
      setFormModel(pformModel);
    },
    [],
  );

  const { mutate: updateUserMutation, isLoading: updateUserInProgress } =
    useMutation(updateUser, {
      onSuccess: () => {
        onNext();
      },
      onError: (err: Error) => {
        if (axios.isAxiosError(err)) {
          const message = (err.response?.data as any)?.error;
          if (message !== null) {
            setError(new Error(message));
            setTimeout(() => {
              setError(null);
            }, 5000);
            return;
          }
        }
        setError(err);
        setTimeout(() => {
          setError(null);
        }, 3000);
      },
    });
  const next = useCallback(() => {
    if (user) {
      const hasDate =
        formModel?.dobMonth != null &&
        formModel?.dobDate != null &&
        formModel?.dobYear != null;

      updateUserMutation({
        id: user?.id,
        properties: {
          details: {
            firstName: formModel?.firstName,
            lastName: formModel?.lastName,
            primAddress1: formModel?.address,
            primZip: formModel?.zip,
            primCity: formModel?.city,
            primState: formModel?.state,
            primCountry: 'USA',
            domicile: formModel?.domicile,
            dob:
              hasDate && formModel
                ? getFormattedDob(
                    formModel.dobMonth!,
                    formModel.dobDate!,
                    formModel.dobYear!,
                  )
                : undefined,
          },
        },
      });
    }
  }, [formModel, updateUserMutation, user]);

  return (
    <Pane>
      {error && (
        <Alert
          intent="danger"
          title="Error Updating Profile"
          marginBottom="1rem"
        >
          {error.message}
        </Alert>
      )}
      <Pane marginTop="2rem">
        <Heading>Set up your account profile:</Heading>
        <Pane marginTop="1rem">
          <ProfileForm
            user={user}
            isLoading={updateUserInProgress}
            onFormModelChange={onProfileFormModelChange}
          />
        </Pane>
        <ButtonWithAnalytics
          analyticName="accountSetupNextBtn"
          onClick={next}
          isLoading={updateUserInProgress}
          disabled={updateUserInProgress}
          appearance="primary"
        >
          Next
        </ButtonWithAnalytics>
      </Pane>
    </Pane>
  );
};
