/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-confusing-arrow */
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { Netgreen_Acorn as Logo } from "@netgreen/assets";
import { Heading, Pane, Spinner } from "evergreen-ui";
import { ReactNode } from "react";
import Helmet from "react-helmet";
import { breakpoints } from "../breakpoints";

const Container = styled(Pane, {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && prop !== "noPadding" && prop !== "noXMargin",
})<{ noPadding?: boolean; noXMargin?: boolean; fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ noPadding }) => (noPadding ? "0rem" : "2rem")};
  padding-bottom: ${({ noPadding }) => (noPadding ? "0rem" : "2rem")};

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "90%")};
  margin-left: ${({ noPadding }) => (noPadding ? "0rem" : "auto")};
  margin-right: ${({ noPadding }) => (noPadding ? "0rem" : "auto")};

  @media (min-width: ${breakpoints.tablet}px) {
    padding-left: ${({ noPadding, noXMargin }) =>
      noPadding || noXMargin ? "0rem" : "5rem"};
    padding-right: ${({ noPadding, noXMargin }) =>
      noPadding || noXMargin ? "0rem" : "5rem"};
    width: 100%;
  }
`;

interface PageContentProps {
  pageTitle: string;
  description?: string;
  hideTitle?: boolean;
  noPadding?: boolean;
  children: ReactNode;
  isLoading?: boolean;
  noXMargin?: boolean;
  fullWidth?: boolean;
}
export const PageContent = ({
  pageTitle,
  hideTitle,
  description,
  noPadding,
  noXMargin = false,
  children,
  isLoading = false,
  fullWidth = false,
}: PageContentProps) => {
  return (
    <Container
      noPadding={noPadding}
      noXMargin={noXMargin}
      fullWidth={fullWidth}
    >
      <Pane display="flex" flexDirection="column">
        {pageTitle && (
          <Pane>
            <Helmet
              title={pageTitle}
              meta={[
                {
                  property: "og:title",
                  content: pageTitle,
                },
                {
                  property: "og:type",
                  content: "website",
                },
                {
                  name: "twitter:card",
                  content: "summary",
                },
                {
                  name: "twitter:title",
                  content: pageTitle,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
                {
                  name: "og:image",
                  content: Logo,
                },
                {
                  name: "og:image:height",
                  content: "1200",
                },
                {
                  name: "og:image:width",
                  content: "1200",
                },
                description
                  ? { name: "description", content: description }
                  : {},
                description
                  ? { name: "og:description", content: description }
                  : {},
              ]}
            />
            {!hideTitle && (
              <Heading size={900} marginBottom="1rem">
                {pageTitle}
              </Heading>
            )}
          </Pane>
        )}
        {isLoading && (
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={400}
          >
            <Spinner />
          </Pane>
        )}
        {!isLoading && <Pane>{children}</Pane>}
      </Pane>
    </Container>
  );
};
