import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { Pane, Text, TickCircleIcon } from 'evergreen-ui';
import { navigate } from 'gatsby';
import React from 'react';
import { AppRoutes, DashboardRoutes } from '../../../constants';

export const AddAccountSuccess = () => (
  <Pane display="flex" flexDirection="column">
    <Pane marginBottom="2rem" display="flex" alignItems="center">
      <TickCircleIcon color="success" marginRight={16} size={32} />
      <Text>Account added successfully</Text>
    </Pane>
    <Pane>
      <ButtonWithAnalytics
        analyticName="BackToAccountsBtn"
        onClick={() => {
          navigate(`/${AppRoutes.dashboard}/${DashboardRoutes.accounts}`);
        }}
      >
        Back to Accounts
      </ButtonWithAnalytics>
    </Pane>
  </Pane>
);
