import { BaseClient } from '../../base-client';
import {
  ContactOptions,
  ContactResult,
  IssuerApplyOptions,
  IssuerApplyResult,
} from '../models';

interface IContactClient {
  contact: (contactOptions: ContactOptions) => Promise<ContactResult>;
  apply: (contactOptions: IssuerApplyOptions) => Promise<IssuerApplyResult>;
}

class ContactClient extends BaseClient implements IContactClient {
  constructor(private baseUrl: string) {
    super(baseUrl);
  }

  async contact(contactOptions: ContactOptions): Promise<ContactResult> {
    return (await this.httpClient.post('/v1/contact', contactOptions)).data;
  }

  async apply(applyOptions: IssuerApplyOptions): Promise<IssuerApplyResult> {
    return (await this.httpClient.post('/v1/apply', applyOptions)).data;
  }
}

export const getContactClient = (baseUrl: string) => new ContactClient(baseUrl);
