/* eslint-disable prefer-template */
import { updatePhoneNumber } from '@netgreen/auth';
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import { Heading, Pane, Text, TextInputField, useTheme } from 'evergreen-ui';
import React, { useCallback, useState } from 'react';
import { NumberFormatValues, PatternFormat } from 'react-number-format';
import { User } from '@netgreen/clients';

interface UpdatePhoneNumberProps {
  user: User | undefined;
}

export const UpdatePhoneNumber = ({ user }: UpdatePhoneNumberProps) => {
  const theme = useTheme();
  const [phone, setPhone] = useState<string>(
    user?.phoneNumber?.substring(2) ?? '',
  );
  const [isEditing, setIsEditing] = useState(false);
  const onInputChange = useCallback(({ value }: NumberFormatValues) => {
    setPhone(value);
  }, []);

  const {
    mutate: updatePhoneNumberMutation,
    isLoading: updatePhoneNumberInProgress,
  } = useMutation(updatePhoneNumber, {
    onSuccess: () => {
      setIsEditing(false);
    },
    onError: (error) => {
      console.log('update phone err', error);
      // setErrorMessage({ title: 'Error', message: error.message });
    },
  });

  const onSubmit = useCallback(() => {
    updatePhoneNumberMutation(`+1${phone}`);
  }, [phone, updatePhoneNumberMutation]);

  return (
    <Pane>
      {isEditing && (
        <Pane>
          <PatternFormat
            customInput={TextInputField}
            name="phone"
            placeholder="(123) 456-7890"
            width="100%"
            label="Phone number"
            disabled={updatePhoneNumberInProgress}
            format="+1 (###) ### ####"
            allowEmptyFormatting
            mask="_"
            onValueChange={onInputChange}
            defaultValue={phone}
          />
          <Pane display="flex" gap="0.5rem">
            <ButtonWithAnalytics
              analyticName="editPhoneNumberCancelBtn"
              disabled={updatePhoneNumberInProgress}
              onClick={() => {
                setIsEditing(false);
                setPhone(user?.phoneNumber?.substring(2) ?? '');
              }}
            >
              Cancel
            </ButtonWithAnalytics>
            <ButtonWithAnalytics
              analyticName="updatePhoneNumberSubmitBtn"
              isLoading={updatePhoneNumberInProgress}
              onClick={onSubmit}
              appearance="primary"
            >
              Update Phone Number
            </ButtonWithAnalytics>
          </Pane>
        </Pane>
      )}
      {!isEditing && (
        <Pane>
          <Heading size={400}>Phone number</Heading>
          <Pane marginTop="1rem" marginBottom="1rem">
            {user && user.phoneNumber && (
              <Text>
                <PatternFormat
                  format="+1 (###) ### ####"
                  allowEmptyFormatting
                  displayType="text"
                  value={phone}
                />
              </Text>
            )}
          </Pane>
          <Pane>
            <ButtonWithAnalytics
              size="small"
              textDecoration="underline"
              appearance="minimal"
              color={theme.colors.blue600}
              analyticName="editPhoneNumberBtn"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </ButtonWithAnalytics>
          </Pane>
        </Pane>
      )}
    </Pane>
  );
};
