import { Dialog, Pane, Text } from 'evergreen-ui';
import * as React from 'react';
import { SocialLinks } from '../social-links';

interface IShareCardProps {
  open: any;
  handleClose: any;
  url: string;
  title?: string;
  shareText?: string;
}

export const ShareCard = ({
  open,
  handleClose,
  url,
  title,
  shareText,
}: IShareCardProps) => {
  const offeringPost = shareText
    ? encodeURIComponent(`${shareText}\n${url}`)
    : encodeURIComponent(url);
  const shareTitle = `Share ${title}`;
  return (
    <Pane>
      <Dialog
        isShown={open}
        hasFooter={false}
        title={shareTitle}
        onCloseComplete={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Text alignItems="center">Share via</Text>
        <br />
        <SocialLinks
          iconTheme="dark"
          iconHeight="3rem"
          socialConfig={[
            {
              name: 'facebook',
              link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            },
            {
              name: 'twitter',
              link: `https://twitter.com/intent/tweet?text=${offeringPost}`,
            },
            {
              name: 'email',
              link: `mailto:?subject=${
                title ? encodeURIComponent(title!) : null
              }&body=${offeringPost}`,
            },
          ]}
        />
      </Dialog>
    </Pane>
  );
};
