import {
  Card,
  CaretRightIcon,
  Heading,
  Icon,
  Image,
  Link,
  Pane,
  Text,
} from 'evergreen-ui';
import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';

interface ResourceCardProps {
  title: string;
  subtitle: string;
  url: string;
  time: number;
  coverImage: string;
}
export const ResourceCard = ({
  title,
  subtitle,
  url,
  time,
  coverImage,
}: ResourceCardProps) => (
  <Card
    elevation={3}
    width="20rem"
    minHeight="20rem"
    borderRadius={15}
    overflow="hidden"
    display="flex"
    flexDirection="column"
  >
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      height="10rem"
    >
    <Image src={coverImage} minWidth="100%"  minHeight="100%" />
    </Pane>
    <Pane paddingX="1rem" paddingY="1rem" display="flex" flexDirection="column">
      <Heading marginBottom="1rem">{title}</Heading>
      <Text marginBottom="1rem">{subtitle}</Text>
      <Pane marginBottom="1rem">
        <Text fontStyle="italic">{time?.toFixed(0)} min</Text>
      </Pane>
      <Pane display="flex" marginLeft="auto">
        <Link to={url} is={GatsbyLink} display="flex" alignItems="center">
          <span>Read more </span>
          <Icon icon={CaretRightIcon} />
        </Link>
      </Pane>
    </Pane>
  </Card>
);
