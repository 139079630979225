import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import {
  Alert,
  Checkbox,
  Heading,
  Link,
  Pane,
  RadioGroup,
  Text,
  toaster,
} from 'evergreen-ui';
import { Link as GatsbyLink } from 'gatsby';
import React, { useCallback, useState } from 'react';
import {
  AccreditedInvestorQualification,
  User,
  updateUser,
} from '@netgreen/clients';
import { AppRoutes } from '../../constants';

interface InvestmentLimitsProps {
  user: User | undefined;
  onSuccess?: () => void;
  showNotAccreditedSubtitle?: boolean;
  nonAccreditedButtonText?: string;
}
export const InvestmentLimits = ({
  user,
  onSuccess,
  nonAccreditedButtonText = 'Skip',
  showNotAccreditedSubtitle = true,
}: InvestmentLimitsProps) => {
  const [options] = useState<
    {
      label: string;
      value: AccreditedInvestorQualification;
    }[]
  >([
    {
      label: 'Individual with net-worth of $1M+ (with or without spouse)',
      value: 'networth-over-million',
    },
    {
      label:
        'Individual with annual income in last two years of $200K+ ($300K+ with spouse)',
      value: 'annual-income-over-200k',
    },
    {
      label:
        'Entity or trust not formed for the specific purpose of investing with $5M+ in assets',
      value: 'entity-or-trust-with-5m-assets',
    },
    {
      label: 'Entity with all accredited investors by the standards above',
      value: 'entity-with-all-accredited-investors',
    },
    {
      label:
        'FINRA-licensed individual in good standing with Series 7, 65 or 82',
      value: 'finra-licensed-individual',
    },
  ]);

  const [value, setValue] = useState<
    AccreditedInvestorQualification | undefined
  >(user?.accreditedInvestorQualification ?? undefined);
  const [checked, setChecked] = useState(user?.accreditedInvestor ?? false);
  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const { mutate: updateUserMutation, isLoading: updateUserInProgress } =
    useMutation(updateUser, {
      onSuccess: () => {
        onSuccess?.();
        toaster.success('Success', {
          description: 'Successfully updated your investor status',
        });
      },
      onError: () => {
        setErrorMessage({
          title: 'Error',
          message: 'An error occurred, please try again',
        });
      },
    });

  const onUpdate = useCallback(() => {
    setErrorMessage(undefined);
    if (!user) {
      return;
    }
    updateUserMutation({
      id: user.id,
      properties: {
        accreditedInvestor: checked,
        accreditedInvestorQualification: checked ? value : undefined,
      },
    });
  }, [checked, updateUserMutation, user, value]);

  return (
    <Pane>
      <Heading marginBottom="1rem">Your investment limits</Heading>
      {errorMessage && (
        <Alert
          intent="danger"
          title={errorMessage ? errorMessage.title : 'Error'}
          marginBottom="1rem"
          marginTop="1rem"
        >
          {errorMessage ? errorMessage?.message : 'An unknown error occurred'}
        </Alert>
      )}
      <Pane>
        <Text>
          The SEC regulates how much you can invest in private offerings. The
          limits vary by the offering regulation type and are based on your net
          worth and annual income.{' '}
          <Link to={`/${AppRoutes.faq}`} is={GatsbyLink}>
            Learn more
          </Link>
        </Text>
      </Pane>
      <Pane marginBottom="2rem" marginTop="1rem">
        <Checkbox
          label="I qualify as an accredited investor"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </Pane>
      {checked && (
        <Pane>
          <Heading marginBottom="1rem">
            How are you an accredited investor?
          </Heading>
          <RadioGroup
            size={16}
            value={value}
            options={options}
            onChange={
              (event) =>
                setValue(event.target.value as AccreditedInvestorQualification)
              // eslint-disable-next-line react/jsx-curly-newline
            }
          />
          <Pane marginY="2rem">
            <Text size="small">
              If you believe you qualify as an accredited investor for a reason
              not listed above, please reach out to support@netgreen.com to
              clarify.
            </Text>
          </Pane>
          <Pane>
            <ButtonWithAnalytics
              analyticName="UpdateAccreditedInvestorBtn"
              appearance="primary"
              onClick={onUpdate}
              isLoading={updateUserInProgress}
            >
              Update
            </ButtonWithAnalytics>
          </Pane>
        </Pane>
      )}
      {!checked && (
        <Pane
          display="flex"
          flexDirection="column"
          marginTop="2rem"
          width="fit-content"
        >
          {showNotAccreditedSubtitle && (
            <Text size="small">Not an accredited investor?</Text>
          )}
          <ButtonWithAnalytics
            analyticName="NotAccreditedInvestorBtn"
            // maxWidth="4rem"
            marginTop="0.5rem"
            appearance="primary"
            onClick={onUpdate}
            isLoading={updateUserInProgress}
          >
            {nonAccreditedButtonText}
          </ButtonWithAnalytics>
        </Pane>
      )}
    </Pane>
  );
};
