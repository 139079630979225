import { getOfferingClient } from '../client';
import {
  DeleteCommentParams,
  IComment,
  Offering,
  OfferingCategory,
  PostCommentParams,
} from '../models';

const baseUrl = `${process.env['GATSBY_API_BASE_URL']}/offering`;

export const getOffering = async (offeringId: string): Promise<Offering> => {
  const client = getOfferingClient(baseUrl);
  return client.getOffering(offeringId);
};

export const getOfferings = async (
  category: OfferingCategory,
): Promise<Offering[]> => {
  const client = getOfferingClient(baseUrl);
  return client.getOfferings(category);
};

export const getTrending = async (): Promise<Record<string, Offering>> => {
  const client = getOfferingClient(baseUrl);
  return client.getTrending();
};

export const postComment = async (postCommentParams: PostCommentParams) => {
  const client = getOfferingClient(baseUrl);
  return client.postComment(postCommentParams);
};

export const getCommentsByOfferingId = async (
  offeringId: string,
): Promise<{ comments: IComment[] }> => {
  if (!offeringId) {
    return Promise.resolve({ comments: [] });
  }
  const client = getOfferingClient(baseUrl);
  return client.getCommentsByOfferingId(offeringId);
};

export const deleteComment = async ({
  commentId,
  offeringId,
}: DeleteCommentParams) => {
  const client = getOfferingClient(baseUrl);
  return client.deleteComment(offeringId, commentId);
};
