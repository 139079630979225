import { User } from '@netgreen/clients';
import { Pane, SelectField, TextInputField } from 'evergreen-ui';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { statesAbbreviated } from '.';

interface AddressFormProps {
  user: User | undefined;
  isLoading: boolean;
  isFormDisabled: boolean;
  onFormModelChange?: (formModel: AddressFormModel) => void;
}

export interface AddressFormModel {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string | undefined;
  domicile?: boolean;
}

export const defaultAddressModel = (
  user: User | undefined,
): AddressFormModel => ({
  firstName: user?.name ?? '',
  lastName: user?.lastName ?? '',
  address: user?.details?.primAddress1 ?? '',
  address2: '',
  city: user?.details?.primCity ?? '',
  state: user?.details?.primState ?? '',
  zip: user?.details?.primZip ?? '',
  phone: user?.phoneNumber,
});

export const AddressForm = ({
  user,
  isLoading = false,
  onFormModelChange,
  isFormDisabled = false,
}: AddressFormProps) => {
  const [formModel, setFormModel] = useState<AddressFormModel>(
    defaultAddressModel(user),
  );

  const onInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { target } = event;
      const value =
        target.type === 'checkbox'
          ? (target as HTMLInputElement).checked
          : target.value;
      const { name } = target;

      setFormModel({
        ...formModel,
        [name]: value,
      });
    },
    [formModel],
  );

  useEffect(() => {
    onFormModelChange?.(formModel);
  }, [formModel, onFormModelChange]);

  return (
    <Pane display="flex" flexDirection="column">
      <TextInputField
        name="address"
        placeholder="Address"
        marginBottom="1rem"
        width="100%"
        label="Address"
        onChange={onInputChange}
        value={formModel.address}
        disabled={isLoading || isFormDisabled}
      />

      <TextInputField
        name="address2"
        placeholder="Address (optional)"
        marginBottom="1rem"
        width="100%"
        label=""
        onChange={onInputChange}
        value={formModel.address2}
        disabled={isLoading || isFormDisabled}
      />
      <Pane display="flex" flexDirection="row">
        <TextInputField
          name="city"
          placeholder="City"
          marginBottom="1rem"
          paddingRight="0.5rem"
          width="100%"
          label="City"
          onChange={onInputChange}
          value={formModel.city}
          disabled={isLoading || isFormDisabled}
        />
        <SelectField
          name="state"
          label="State"
          paddingRight="0.5rem"
          required
          value={formModel.state}
          onChange={onInputChange}
          disabled={isLoading || isFormDisabled}
        >
          {statesAbbreviated.map((m) => (
            <option key={m.value} value={m.value}>
              {m.label}
            </option>
          ))}
        </SelectField>
        <TextInputField
          name="zip"
          placeholder="Zip"
          marginBottom="1rem"
          paddingLeft="0.5rem"
          width="100%"
          label="Zip"
          onChange={onInputChange}
          value={formModel.zip}
          disabled={isLoading || isFormDisabled}
        />
      </Pane>
    </Pane>
  );
};
