import axios, { AxiosInstance } from 'axios';
import { CognitoUser, getCognitoUser, getToken } from '@netgreen/auth';

export interface IAuthHeaders {
  Authorization: string;
}

export abstract class BaseClient {
  protected httpClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.httpClient = axios.create({
      baseURL: baseUrl,
      timeout: 30000,
      headers: {
        'x-api-client': 'netgreen-web',
      },
    });
  }

  protected async getAuthHeaders(): Promise<IAuthHeaders> {
    const token = await this.getToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  private async getToken(): Promise<string> {
    return getToken();
  }

  protected async getCognitoUser(): Promise<CognitoUser | undefined> {
    return getCognitoUser();
  }
}
