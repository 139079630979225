import { BaseClient } from '../../base-client';
import {
  CommentResponse,
  IComment,
  Offering,
  OfferingCategory,
  PostCommentParams,
} from '../models';

interface IOfferingClient {
  getOffering: (offeringId: string) => Promise<Offering>;
  getOfferings: (category: OfferingCategory) => Promise<Offering[]>;
  getTrending: () => Promise<Record<string, Offering>>;
  getCommentsByOfferingId(
    offeringId: string,
  ): Promise<{ comments: IComment[] }>;
  postComment(postCommentParams: PostCommentParams): Promise<IComment>;
  deleteComment(offeringId: string, commentId: string): Promise<void>;
}

class OfferingClient extends BaseClient implements IOfferingClient {
  constructor(private baseUrl: string) {
    super(baseUrl);
  }

  async getOffering(offeringId: string): Promise<Offering> {
    return (await this.httpClient.get(`/v1/offering/${offeringId}`)).data;
  }

  async getTrending(): Promise<Record<string, Offering>> {
    return (await this.httpClient.get('/v1/offerings/trending')).data;
  }

  async getOfferings(category: OfferingCategory): Promise<Offering[]> {
    return (
      await this.httpClient.get('/v1/offerings', {
        params: {
          category,
        },
      })
    ).data;
  }

  // GET /v1/offering/uuid/comments
  // POST /v1/offering/uuid/comments
  // GET /v1/offering/uuid/comments/id
  // DELETE /v1/offering/uuid/comments/id
  async postComment(postCommentParams: PostCommentParams): Promise<IComment> {
    const authHeaders = await this.getAuthHeaders();

    const body = {
      comment: postCommentParams.comment,
    };

    return (
      await this.httpClient.post(
        `/v1/offering/${postCommentParams.offeringId}/comments`,
        body,
        { headers: { ...authHeaders } },
      )
    ).data;
  }

  async getCommentsByOfferingId(offeringId: string): Promise<CommentResponse> {
    const { data } = await this.httpClient.get(
      `/v1/offering/${offeringId}/comments`,
    );

    const formattedComments = (data as any)?.comments?.map((c: any) => ({
      ...c,
      createdAt: new Date(c.createdAt),
      updatedAt: new Date(c.updatedAt),
    }));

    return { ...data, comments: formattedComments };
  }

  async deleteComment(offeringId: string, commentId: string): Promise<void> {
    const authHeaders = await this.getAuthHeaders();
    return (
      await this.httpClient.delete(
        `/v1/offering/${offeringId}/comments/${commentId}`,
        { headers: { ...authHeaders } },
      )
    ).data;
  }
}

export const getOfferingClient = (baseUrl: string) =>
  new OfferingClient(baseUrl);
