export class PasswordPolicyError extends Error {
  override name = 'PasswordPolicyException';

  override message =
    'Password must be at least 8 characters and have at least one number, upper case letter, lowercase letter, and special character';
}

export class UsernameExistsError extends Error {
  override name = 'UsernameExistsException';

  override message = 'An account with the given email already exists.';
}

export class ExpiredCodeError extends Error {
  override name = 'ExpiredCodeException';

  override message = 'Invalid code provided, please request a code again.';
}

export class CodeMismatchError extends Error {
  override name = 'CodeMismatchException';

  override message = 'Invalid verification code provided, please try again.';
}

export class ForgotPasswordSignInError extends Error {
  override name = 'ForgotPasswordSignInError';

  override message = 'Sign in failed after resetting password. Please try again.';
}

export class LimitExceededError extends Error {
  override name = 'LimitExceededException';

  override message = 'Attempt limit exceeded, please try after some time.';
}

export class NotAuthorizedError extends Error {
  override name = 'NotAuthorizedException';

  override message = 'Incorrect username or password.';

  constructor(message: string) {
    super();
    if (message === 'Password attempts exceeded') {
      this.message = message;
    }
  }
}

export const isHandledAuthError = (error: Error): boolean => {
  if (
    error instanceof PasswordPolicyError ||
    error instanceof UsernameExistsError ||
    error instanceof ExpiredCodeError ||
    error instanceof CodeMismatchError ||
    error instanceof LimitExceededError ||
    error instanceof NotAuthorizedError
  ) {
    return true;
  }

  return false;
};
