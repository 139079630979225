/* eslint-disable prefer-template */
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import {
  Alert,
  Checkbox,
  Link,
  Pane,
  Text,
  TextInputField,
} from 'evergreen-ui';
import { useFormik } from 'formik';
import { Link as GatsbyLink } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';
import { Attestations, User, updateUser } from '@netgreen/clients';
import { AppRoutes } from '../../constants';

export interface InvestorVerificationModel {
  socialSecurityNumber: string | undefined;
  currentAnnIncome: number | undefined;
  householdNetworth: number | undefined;
  attestations: Attestations | undefined;
}

const attestation = yup.object({
  accepted_platform_fee: yup.boolean(),
  accepted_cancel_restriction: yup.boolean(),
  accepted_resale: yup.boolean(),
  accepted_risk: yup.boolean(),
  accepted_reviewed_education: yup.boolean(),
});

const schema = yup.object({
  socialSecurityNumber: yup
    .string()
    .length(9)
    .defined('Required')
    .required('Required'),
  currentAnnIncome: yup.number().required('Required'),
  householdNetworth: yup.number().required('Required'),
  attestation,
});

const defaultModel: InvestorVerificationModel = {
  socialSecurityNumber: undefined,
  currentAnnIncome: undefined,
  householdNetworth: undefined,
  attestations: {
    accepted_platform_fee: false,
    accepted_cancel_restriction: false,
    accepted_resale: false,
    accepted_risk: false,
    accepted_reviewed_education: false,
  },
};

interface InvestorVerificationFormProps {
  user: User | undefined;
  nextButtonTitle?: string;
  onNext?: () => void;
}
export const InvestorVerificationForm = ({
  user,
  nextButtonTitle,
  onNext,
}: InvestorVerificationFormProps) => {
  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const [checkedCheckboxes, setCheckedCheckboxes] = useState<string[]>([]);
  const handleCheckboxChange = (event: any) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedCheckboxes((prevArray) => [...prevArray, checkboxName]);
    } else {
      const newArray = checkedCheckboxes.filter((c) => c !== event.target.name);
      setCheckedCheckboxes(newArray);
    }
  };

  useEffect(() => {
    if (user && user.attestations) {
      setCheckedCheckboxes(
        Object.keys(user.attestations).filter(
          (key: string) =>
            user.attestations !== undefined &&
            user.attestations[key as keyof Attestations] !== undefined,
        ),
      );
    }
  }, [user]);

  const { mutate: updateUserMutation, isLoading: updateUserInProgress } =
    useMutation(updateUser, {
      onSuccess: () => {
        onNext?.();
        console.log('success');
      },
      onError: (error) => {
        setErrorMessage({
          title: 'Error',
          message: 'Error updating user account',
        });
      },
    });

  const {
    handleSubmit,
    handleChange: onInputChange,
    handleBlur,
    touched,
    errors,
    submitCount,
    setFieldValue,
  } = useFormik({
    initialValues: defaultModel,
    validationSchema: schema,
    onSubmit: (formValues) => {
      if (!user) {
        setErrorMessage({
          title: 'Invalid User',
          message: 'User is not valid',
        });
        return;
      }
      // Equal to number of checkboxes
      if (checkedCheckboxes.length !== 5) {
        setErrorMessage({
          title: 'Missing information',
          message: 'Please accept all attestations.',
        });
        return;
      }
      updateUserMutation({
        id: user.id,
        properties: {
          attestations: {
            accepted_platform_fee: checkedCheckboxes.includes(
              'accepted_platform_fee',
            ),
            accepted_cancel_restriction: checkedCheckboxes.includes(
              'accepted_cancel_restriction',
            ),
            accepted_resale: checkedCheckboxes.includes('accepted_resale'),
            accepted_risk: checkedCheckboxes.includes('accepted_risk'),
            accepted_reviewed_education: checkedCheckboxes.includes(
              'accepted_reviewed_education',
            ),
          },
          details: {
            socialSecurityNumber: formValues.socialSecurityNumber,
            currentAnnIncome: formValues.currentAnnIncome,
            householdNetworth: formValues.householdNetworth,
          },
        },
      });
    },
  });

  const hasErrors = submitCount > 0 && Object.keys(errors).length > 0;
  const submittedOnce = submitCount > 0;

  return (
    <Pane>
      <Pane>
        {(hasErrors || errorMessage) && (
          <Alert
            intent="danger"
            title={errorMessage ? errorMessage.title : 'Missing information'}
            marginBottom="1rem"
            marginTop="1rem"
          >
            {errorMessage
              ? errorMessage?.message
              : 'Please fill out the required fields.'}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextInputField
            name="socialSecurityNumber"
            placeholder="123-45-6789"
            width="100%"
            label="Social Security #"
            type="text"
            maxLength={9}
            disabled={updateUserInProgress}
            onChange={onInputChange}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce &&
              touched.socialSecurityNumber &&
              errors.socialSecurityNumber
            }
          />

          <NumericFormat
            customInput={TextInputField}
            name="currentAnnIncome"
            type="text"
            placeholder="Enter amount in USD"
            width="100%"
            label="Annual Income"
            disabled={updateUserInProgress}
            allowNegative={false}
            valueIsNumericString
            onValueChange={({ floatValue }) => {
              setFieldValue('currentAnnIncome', floatValue);
            }}
            prefix="$"
            decimalScale={0}
            fixedDecimalScale
            thousandSeparator=","
            max={100000000000}
            defaultValue={user?.details?.currentAnnIncome}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce &&
              touched.currentAnnIncome &&
              errors.currentAnnIncome
            }
          />
          <NumericFormat
            customInput={TextInputField}
            name="householdNetworth"
            placeholder="Enter amount in USD"
            width="100%"
            label="Household Networth"
            disabled={updateUserInProgress}
            valueIsNumericString
            onValueChange={({ floatValue }) => {
              setFieldValue('householdNetworth', floatValue);
            }}
            prefix="$"
            decimalScale={0}
            fixedDecimalScale
            thousandSeparator=","
            defaultValue={user?.details?.householdNetworth}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce &&
              touched.householdNetworth &&
              errors.householdNetworth
            }
          />
          <Pane>
            <Text>Attestations</Text>
            <Checkbox
              disabled={updateUserInProgress}
              label={
                <Text>
                  I understand there are restrictions on my ability to cancel an
                  investment commitment and obtain a return of investment
                  (Cancellations must be performed by 48 hours before the
                  Offering deadline with the exception of material changes to
                  the Offering).
                </Text>
              }
              name="accepted_cancel_restriction"
              checked={checkedCheckboxes.includes(
                'accepted_cancel_restriction',
              )}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              disabled={updateUserInProgress}
              label={
                <Text>
                  I understand that it may be difficult to resell Securities
                  acquired in reliance on the Crowdfunding Exemption.
                </Text>
              }
              checked={checkedCheckboxes.includes('accepted_resale')}
              name="accepted_resale"
              onChange={handleCheckboxChange}
            />
            <Checkbox
              disabled={updateUserInProgress}
              label={
                <Text>
                  I understand that investing in securities offered and sold in
                  reliance on the Crowdfunding Exemption involves risk, and I
                  should not invest any funds in an offering made in reliance on
                  the Crowdfunding Exemption unless I can afford to lose the
                  entire amount of my investment.
                </Text>
              }
              checked={checkedCheckboxes.includes('accepted_risk')}
              name="accepted_risk"
              onChange={handleCheckboxChange}
            />
            <Checkbox
              disabled={updateUserInProgress}
              label={
                <Text>
                  I have reviewed the{' '}
                  <Link
                    to={`/${AppRoutes.education}`}
                    is={GatsbyLink}
                    target="_blank"
                  >
                    Education Materials
                  </Link>{' '}
                  provided.
                </Text>
              }
              checked={checkedCheckboxes.includes(
                'accepted_reviewed_education',
              )}
              name="accepted_reviewed_education"
              onChange={handleCheckboxChange}
            />
            <Checkbox
              disabled={updateUserInProgress}
              label={
                <Text>
                  I understand that NetGreen.com charges a platform fee for each
                  investment commitment. This is not a recurring charge and will
                  be held in escrow until the investment round closes. Please
                  visit our{' '}
                  <Link to="/legal/fees" is={GatsbyLink} target="_blank">
                    Platform Fee Structure page
                  </Link>{' '}
                  to learn more.
                </Text>
              }
              checked={checkedCheckboxes.includes('accepted_platform_fee')}
              name="accepted_platform_fee"
              onChange={handleCheckboxChange}
            />
          </Pane>
          <ButtonWithAnalytics
            type="submit"
            analyticName="updatePhoneNumberSubmitBtn"
            isLoading={updateUserInProgress}
            appearance="primary"
          >
            {nextButtonTitle ?? 'Update'}
          </ButtonWithAnalytics>
        </form>
      </Pane>
    </Pane>
  );
};
