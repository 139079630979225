/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { PageContent, breakpoints } from '@netgreen/core-ui';
import { Pane, Spinner } from 'evergreen-ui';
import React from 'react';
import { useCurrentUser } from '../../components/auth/useCurrentUser';
import { AddAccountFlow } from './add-account-flow';

const Container = styled(Pane)`
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 45rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const AddAccountPage = () => {
  const { data: user, isLoading: isLoadingUser } = useCurrentUser();

  if (isLoadingUser || !user) {
    return (
      <Pane display="flex" justifyContent="center" margin="auto" height="100%">
        <Spinner />
      </Pane>
    );
  }

  return (
    <Container>
      <PageContent pageTitle="Add Account" noXMargin>
        <AddAccountFlow />
      </PageContent>
    </Container>
  );
};
