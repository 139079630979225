import { Heading, Pane, Text } from 'evergreen-ui';
import * as React from 'react';
import { FAQ } from '../../models/faq.interface';
import { MarkdownContent } from '../markdown-content';

interface FAQComponentProps {
  faq: FAQ;
}

export const FAQComponent = ({ faq }: FAQComponentProps) => (
  <Pane>
    <Heading size={600} marginBottom="0.5rem">
      {faq.question}
    </Heading>
    <Pane marginBottom="1rem">
      <Text>
        <MarkdownContent content={faq.answer} />
      </Text>
    </Pane>
    <hr />
  </Pane>
);
