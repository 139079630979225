import {
  AddExternalAccountError,
  addExternalAccount,
} from '@netgreen/clients';
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import { Alert, Pane, RadioGroup, TextInputField } from 'evergreen-ui';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

export interface IssuerFormModel {
  accountNumber: string | undefined;
  confirmAccountNumber: string | undefined;
  routingNumber: string | undefined;
  accountName: string | undefined;
  accountType: 'Checking' | 'Savings' | undefined;
}

const schema = yup.object({
  routingNumber: yup.number().defined('Required').required('Required'),
  accountNumber: yup.string().defined('Required').required('Required'),
  confirmAccountNumber: yup.string().required('Required'),
  accountName: yup.string().required('Required'),
  accountType: yup
    .string()
    .required('Required')
    .matches(/Checking|Savings/),
});

const defaultModel: IssuerFormModel = {
  routingNumber: undefined,
  accountNumber: undefined,
  confirmAccountNumber: undefined,
  accountName: undefined,
  accountType: 'Checking',
};

interface ManualAccountProps {
  onSuccess: () => void;
  accountId: string;
}
export const ManualAccount = ({ onSuccess, accountId }: ManualAccountProps) => {
  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const accountOptions = [
    { label: 'Checking', value: 'Checking' },
    { label: 'Savings', value: 'Savings' },
  ];

  const {
    mutate: addExternalAccountMutation,
    isLoading: addExternalAccountInProgress,
  } = useMutation(addExternalAccount, {
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (error: AddExternalAccountError) => {
      setErrorMessage({ title: 'Error', message: error.message });
    },
  });

  const {
    handleSubmit,
    handleChange: onInputChange,
    handleBlur,
    touched,
    errors,
    submitCount,
    values,
  } = useFormik({
    initialValues: defaultModel,
    validationSchema: schema,

    onSubmit: (formValues) => {
      setErrorMessage(undefined);
      if (formValues.accountNumber !== formValues.confirmAccountNumber) {
        setErrorMessage({
          title: 'Invalid Input',
          message: 'Account numbers do not match',
        });
        return;
      }

      addExternalAccountMutation({
        accountId,
        properties: {
          accountName: formValues.accountName!,
          accountNickname: formValues.accountName!,
          accountNumber: formValues.accountNumber!,
          routingNumber: formValues.routingNumber!,
          accountType: formValues.accountType!,
        },
      });
    },
  });

  const hasErrors = submitCount > 0 && Object.keys(errors).length > 0;
  const submittedOnce = submitCount > 0;

  return (
    <Pane>
      <form onSubmit={handleSubmit}>
        <Pane display="flex" flexDirection="column" maxWidth="25rem">
          {(hasErrors || errorMessage) && (
            <Alert
              intent="danger"
              title={errorMessage ? errorMessage.title : 'Missing information'}
              marginBottom="1rem"
              marginTop="1rem"
            >
              {errorMessage
                ? errorMessage?.message
                : 'Please fill out the required fields.'}
            </Alert>
          )}
          <TextInputField
            name="accountName"
            placeholder="Bank name"
            marginBottom="1rem"
            width="100%"
            label="Bank name *"
            disabled={addExternalAccountInProgress}
            onChange={onInputChange}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce && touched.accountName && errors.accountName
            }
            maxLength={100}
          />
          <RadioGroup
            name="accountType"
            label="Account Type *"
            size={16}
            value={values.accountType}
            options={accountOptions}
            onChange={onInputChange}
          />
          <TextInputField
            name="routingNumber"
            placeholder="Routing number"
            marginBottom="1rem"
            width="100%"
            label="Routing number *"
            disabled={addExternalAccountInProgress}
            onChange={onInputChange}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce && touched.routingNumber && errors.routingNumber
            }
            maxLength={9}
          />
          <TextInputField
            name="accountNumber"
            placeholder="Account number"
            marginBottom="1rem"
            width="100%"
            label="Account number *"
            disabled={addExternalAccountInProgress}
            onChange={onInputChange}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce && touched.accountNumber && errors.accountNumber
            }
            maxLength={20}
          />
          <TextInputField
            name="confirmAccountNumber"
            placeholder="Confirm account number"
            marginBottom="1rem"
            width="100%"
            label="Confirm account number *"
            disabled={addExternalAccountInProgress}
            onChange={onInputChange}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce &&
              touched.confirmAccountNumber &&
              errors.confirmAccountNumber
            }
            maxLength={20}
          />
        </Pane>
        <ButtonWithAnalytics
          analyticName="addManualAccountSubmit"
          type="submit"
          isLoading={addExternalAccountInProgress}
          appearance="primary"
        >
          Submit
        </ButtonWithAnalytics>
      </form>
    </Pane>
  );
};
