import { getContactClient } from '../client';
import {
  ContactOptions,
  ContactResult,
  IssuerApplyOptions,
  IssuerApplyResult,
} from '../models';

const baseUrl = `${process.env['GATSBY_API_BASE_URL']}/contact`;

export const contact = async (
  contactOptions: ContactOptions,
): Promise<ContactResult> => {
  const client = getContactClient(baseUrl);
  return client.contact(contactOptions);
};

export const issuerApply = async (
  applyOptions: IssuerApplyOptions,
): Promise<IssuerApplyResult> => {
  const client = getContactClient(baseUrl);
  return client.apply(applyOptions);
};
