import { Analytics } from "@netgreen/analytics";
import { useCurrentCognitoUser } from "@netgreen/auth";
import { Button, ButtonProps } from "evergreen-ui";
import { ForwardedRef, forwardRef, useCallback } from "react";

/* eslint-disable-next-line */
export interface ButtonWithAnalyticsProps extends ButtonProps {
  analyticName: string;
  analyticProperties?: Record<string, unknown>;
  onClick?: () => void;
}

export const ButtonWithAnalytics = ({
  onClick,
  children,
  analyticName,
  analyticProperties = {},
  ref = undefined,
  ...props
}: ButtonWithAnalyticsProps) => {
  const { data: user } = useCurrentCognitoUser();
  const onButtonClick = useCallback(() => {
    onClick?.();
    Analytics.trackEventWithProperties(analyticName, {
      ...analyticProperties,
      user: user?.username,
    });
  }, [analyticName, analyticProperties, onClick, user]);

  return (
    <Button
      data-testid={analyticName}
      onClick={onButtonClick}
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Button>
  );
};

export const ButtonWithAnalyticsWithRef = forwardRef(
  (
    {
      onClick,
      children,
      analyticName,
      analyticProperties = {},
      ...props
    }: ButtonWithAnalyticsProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const onButtonClick = useCallback(() => {
      onClick?.();
      Analytics.trackEventWithProperties(analyticName, analyticProperties);
    }, [analyticName, analyticProperties, onClick]);

    return (
      <Button
        data-testid={analyticName}
        onClick={onButtonClick}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </Button>
    );
  }
);
