import { Heading, Link, Pane, useTheme } from 'evergreen-ui';
import { Link as GatsbyLink } from 'gatsby';
import React, { useCallback } from 'react';
import { LinkConfig } from '../../models';

interface DashboardNavSectionProps {
  linksConfig: LinkConfig[];
  onLinkClick: () => void;
}

export const DashboardNavSection = ({
  linksConfig,
  onLinkClick,
}: DashboardNavSectionProps) => {
  const onClick = useCallback(() => {
    onLinkClick?.();
  }, [onLinkClick]);

  const theme = useTheme();

  return (
    <Pane marginX="-1rem" background={theme.colors.gray90} marginBottom="1rem">
      <Pane marginX="1rem" paddingTop="0.5rem">
        <Heading>My Account</Heading>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        marginX="2rem"
        marginY="0.5rem"
      >
        {linksConfig.map((link) => {
          if (link.type !== 'menu') {
            return (
              <Link
                key={link.name}
                is={GatsbyLink}
                to={link.to}
                paddingY="0.25rem"
                fontSize="1rem"
                onClick={onClick}
              >
                {link.name}
              </Link>
            );
          }

          if (link.type === 'menu') {
            return link.items?.map((subLink) => (
              <Link
                key={subLink.name}
                is={GatsbyLink}
                to={subLink.to}
                paddingY="0.25rem"
                fontSize="1rem"
                onClick={onClick}
              >
                {subLink.name}
              </Link>
            ));
          }

          return undefined;
        })}
      </Pane>
    </Pane>
  );
};
