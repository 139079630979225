import styled from '@emotion/styled';
import { Pane } from 'evergreen-ui';
import React from 'react';

export interface ProgressBarProps {
  backgroundColor?: string;
  progress: number;
  height?: string;
}

const Parent = styled.div<{ height: string | undefined }>`
  height: ${(props) => props.height ?? '0.5rem'};
  width: 100%;
  background-color: whitesmoke;
  border-radius: 40px;
`;

const Progress = styled(Pane)<{
  progress: number;
  backgroundColor: string | undefined;
}>`
  height: 100%;
  width: ${(props: { progress: number }) =>
    props.progress > 100 ? '100%' : `${props.progress.toFixed(0)}%`};
  background-color: ${(props: any) => props.theme.colors.blue500};
  border-radius: 40px;
`;

export const ProgressBar = ({
  backgroundColor,
  progress,
  height,
}: ProgressBarProps) => (
  <Parent height={height}>
    <Progress progress={progress} backgroundColor={backgroundColor} />
  </Parent>
);
