import { useLocation } from '@reach/router';
import {
  CaretDownIcon,
  Icon,
  Link,
  Menu,
  Popover,
  Position,
  useTheme,
} from 'evergreen-ui';
import { Link as GatsbyLink, navigate } from 'gatsby';
import React from 'react';
import { LinkConfig } from '../../models';

interface DropdownButtonProps {
  items: LinkConfig[];
}
export const DropdownButton = ({ items = [] }: DropdownButtonProps) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Group>
            {items.map((item) => (
              <Menu.Item
                key={item.to}
                onSelect={() => {
                  navigate(item.to);
                }}
              >
                {item.name}
              </Menu.Item>
            ))}
          </Menu.Group>
        </Menu>
      }
    >
      <Link
        key="link.name"
        is={GatsbyLink}
        marginRight={16}
        to={location.pathname}
        display="flex"
        color={theme.colors.white}
      >
        <span>Resources</span>
        <Icon
          icon={CaretDownIcon}
          size={12}
          marginLeft="0.1rem"
          marginY="auto"
        />
      </Link>
    </Popover>
  );
};
