/* eslint-disable @typescript-eslint/quotes */
import { useTheme } from '@emotion/react';
import { linkExternalAccount } from '@netgreen/clients';
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useQuery } from '@tanstack/react-query';
import { Badge, Card, Heading, Pane, Text } from 'evergreen-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { AddAccountState } from './models';

interface AddAccountOptionsProps {
  onSuccess: () => void;
  accountId: string | undefined;
  changeState: (newState: AddAccountState) => void;
}
export const AddAccountOptions = ({
  onSuccess,
  accountId,
  changeState,
}: AddAccountOptionsProps) => {
  const [isShown, setIsShown] = useState(false);

  const { data: linkExternalAccountData, isLoading } = useQuery(
    [`current-user-link-external-account-${accountId}`],
    () => linkExternalAccount({ accountId: accountId ?? '' }),
  );

  useEffect(() => {
    const handler = (event: { data: string; origin: string }) => {
      try {
        const data = JSON.parse(event.data);

        if (
          event.origin.includes('norcapsecurities.com') &&
          data.errorCode === '404'
        ) {
          setIsShown(false);
        }

        if (
          event.origin.includes('norcapsecurities.com') &&
          data.statusCode === '101'
        ) {
          setIsShown(false);
          onSuccess();
        }
      } catch (err) {
        console.log('ERR', err);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [onSuccess]);

  const onManualConnect = useCallback(() => {
    changeState(AddAccountState.addManualAccount);
  }, [changeState]);

  const theme = useTheme();

  return (
    <Pane>
      {isShown && (
        <Pane
          backgroundColor={theme.background}
          width="100%"
          height="100%"
          position="fixed"
          top={0}
          left={0}
          zIndex={1}
        >
          <iframe
            width="100%"
            height="100%"
            src={linkExternalAccountData?.externalAccountLink}
            title="Connect Account with Plaid"
          />
        </Pane>
      )}

      <Card border="default" padding="1rem">
        <Pane marginBottom="1rem">
          <Pane display="flex" alignItems="center" marginBottom="1rem">
            <Heading marginY="auto">Connect with Plaid</Heading>
            <Badge color="green" marginLeft="1rem">
              Recommended
            </Badge>
          </Pane>

          <Text>
            {`Enter your bank's username and password to quickly and securely begin investing.`}
          </Text>
        </Pane>
        <Pane>
          <ButtonWithAnalytics
            analyticName="ConnectPlaidBtn"
            onClick={() => setIsShown(true)}
            appearance="primary"
          >
            Connect
          </ButtonWithAnalytics>
        </Pane>
      </Card>

      <Card border="default" padding="1rem" marginTop="2rem">
        <Pane marginBottom="1rem">
          <Heading marginBottom="0.5rem">
            Setup Wire Transfer
          </Heading>
          <Text>
            Please contact admin@netgreen.com for wiring instructions.
          </Text>
        </Pane>
      </Card>
    </Pane>
  );
};
