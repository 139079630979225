import {
  css,
  Global,
  Interpolation,
  Theme,
  ThemeProvider as StyledThemeProvider,
} from '@emotion/react';
import {
  DefaultTheme,
  defaultTheme,
  mergeTheme,
  ThemeProvider,
} from 'evergreen-ui';
import React, { ReactNode, useEffect, useState } from 'react';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends DefaultTheme {
    // ADD TM custom theme vars here
    background: string;
    textColor: string;
  }
}

const netgreenTheme: DefaultTheme = mergeTheme(defaultTheme, {
  colors: {
    blue200: '#e7efe4',
    blue300: '#ccdcc4',
    blue400: '#b0c8a5',
    blue500: '#78a265',
    blue600: '#608450',
    blue700: '#49643d',
    blue800: '#324529',
  },
  components: {
    Link: {
      baseStyle: {
        fontWeight: '500',
      },
    },
  },
});

const lightTheme: Theme = {
  ...netgreenTheme,
  colors: {
    ...netgreenTheme.colors,
  },

  background: '#ffffff',
  textColor: '#000000',
};

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends DefaultTheme {
    // ADD TM custom theme vars here
    background: string;
    textColor: string;
  }
}

export const globalStyles = (theme: Theme): Interpolation<Theme> => css`
  main {
    background: ${theme.background};
    font-family: ${theme.fontFamilies.ui};
    color: ${theme.textColor};
  }
  body {
    font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';
    margin: 0;
    padding: 0;
  }

  .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: ${theme.colors.blue600};
    --rdp-background-color: #f2f6f0;
    --rdp-accent-color-dark: ${theme.colors.blue100};
    --rdp-background-color-dark: ${theme.colors.blue800};
    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  }
  hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  a {
    color: ${theme.colors.blue600};
    text-decoration: none;
  }
`;

interface ThemeProps {
  children?: ReactNode;
}

export const ThemedApp = ({ children }: ThemeProps): JSX.Element => {
  const getCurrentTheme = () =>
    typeof window !== 'undefined' &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const theme = mergeTheme(defaultTheme, isDarkTheme ? lightTheme : lightTheme);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
      darkThemeMq.addEventListener('change', (e) => {
        setIsDarkTheme(e.matches);
      });
    }
  }, []);

  return (
    <>
      <Global styles={globalStyles(theme)} />
      <StyledThemeProvider theme={theme}>
        <ThemeProvider value={theme}>{children}</ThemeProvider>
      </StyledThemeProvider>
    </>
  );
};
