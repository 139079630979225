import axios from 'axios';
import { getUserClient } from '../client';
import {
  AddAccountResponse,
  AddExternalAccountError,
  AddExternalAccountResponse,
  CreateAccountRequest,
  CreateExternalAccountRequest,
  DeepPartial,
  GetAccountResponse,
  LinkExternalAccountRequest,
  LinkExternalAccountResponse,
  User,
} from '../models';

const baseUrl = `${process.env.GATSBY_API_BASE_URL}/user`;
// const baseUrl = `${process.env.GATSBY_API_BASE_URL}/`;

export const getUser = async (): Promise<User> => {
  const client = getUserClient(baseUrl);
  return client.getUser();
};

export const updateUser = async (updateUserRequest: {
  id: string;
  properties: DeepPartial<User>;
}): Promise<User> => {
  const client = getUserClient(baseUrl);
  return client.updateUser(updateUserRequest.id, updateUserRequest.properties);
};

export const addAccount = async (
  addAccountRequest: CreateAccountRequest,
): Promise<AddAccountResponse> => {
  const client = getUserClient(baseUrl);
  return client.addAccount(addAccountRequest);
};

export const getAccounts = async (
  showWire?: boolean,
): Promise<GetAccountResponse[]> => {
  const client = getUserClient(baseUrl);
  return (await client.getAccounts()).filter(
    (account) =>
      account.accountSource === 'external' ||
      account.accountSource === 'plaid' ||
      (account.accountSource === 'wire' && showWire),
  );
};

export const linkExternalAccount = async (
  linkExternalAccountRequest: LinkExternalAccountRequest,
): Promise<LinkExternalAccountResponse> => {
  const client = getUserClient(baseUrl);
  return client.linkExternalAccount(linkExternalAccountRequest);
};

export const addExternalAccount = async (
  createExternalAccountRequest: CreateExternalAccountRequest,
): Promise<AddExternalAccountResponse> => {
  try {
    const client = getUserClient(baseUrl);
    return await client.addExternalAccount(createExternalAccountRequest);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if ((error.response?.data as any).error) {
        throw new AddExternalAccountError((error.response?.data as any).error);
      } else {
        throw new AddExternalAccountError();
      }
    }
    throw error;
  }
};
