import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { User, getUser } from '@netgreen/clients';
import { isLoggedIn } from '@netgreen/auth';
import { useLastAuthEvent } from './useLastAuthEvent';

export const useCurrentUser = () =>
  useQuery<User>(['current-user'], () => getUser());

const authLoginEvents = ['signIn', 'autoSignIn', 'signOut'];

export const useIsLoggedIn = () => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const lastEvent = useLastAuthEvent();

  useEffect(() => {
    const get = async () => {
      const u = await isLoggedIn();
      setIsSignedIn(u);
      setIsLoading(false);
    };

    if (authLoginEvents.includes(lastEvent) || lastEvent.length === 0) {
      get();
    }
  }, [lastEvent]);

  return { isLoggedIn: isSignedIn, isLoading };
};
