import { User } from '@netgreen/clients';
import {
  AddressForm,
  AddressFormModel,
  ButtonWithAnalytics,
  defaultAddressModel,
} from '@netgreen/core-ui';
import { Checkbox, Heading, Pane, SelectField, TextInputField } from 'evergreen-ui';
import React, { ChangeEvent, useCallback, useState } from 'react';

export interface AccountFormModel extends AddressFormModel {
  accountName: string;
  type: string;
}

interface ConfirmAddressProps {
  onNext: (model: AccountFormModel) => void;
  user: User | undefined;
  isLoading: boolean;
}

export const ConfirmAddress = ({
  onNext,
  user,
  isLoading,
}: ConfirmAddressProps) => {
  const [checked, setChecked] = useState(true);
  const [formModel, setFormModel] = useState<AccountFormModel>({
    ...defaultAddressModel(user),
    type: 'Individual',
    accountName: `${user?.name} ${user?.lastName}` ?? '',
  });
  const [submitCount, setSubmitCount] = useState(0);
  const [accountNameEnabled, setAccountNameEnabled] = useState(false);

  const [addressModel, setAddressModel] = useState<AddressFormModel>(
    defaultAddressModel(user),
  );

  const onAddressFormModelChange = useCallback((model: AddressFormModel) => {
    setAddressModel(model);
  }, []);

  const onEntityFormModelChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { target } = event;
      if (target.value === 'Individual') {
        setFormModel({
          ...formModel,
          type: 'Individual',
          accountName: `${user?.name} ${user?.lastName}`,
        });
        setAccountNameEnabled(false);
      } else {
        setFormModel({
          ...formModel,
          type: 'Entity',
          accountName: '',
        });
        setAccountNameEnabled(true);
      }
    },
    [formModel, setFormModel, setAccountNameEnabled, user],
  );

  const handleNextClick = useCallback(() => {
    setSubmitCount((v) => v + 1);

    if (formModel.accountName.length === 0) {
      return;
    }

    onNext({ ...formModel, ...addressModel });
  }, [formModel, onNext, addressModel]);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event;
      const value =
        target.type === 'checkbox'
          ? (target as HTMLInputElement).checked
          : target.value;
      const { name } = target;

      setFormModel({
        ...formModel,
        [name]: value,
      });
    },
    [formModel],
  );

  return (
    <Pane>
      <SelectField
        name="entityType"
        onChange={onEntityFormModelChange}
        defaultValue="Individual"
        label="Entity Type"
        data-testid="entityType"
      >
        <option value="Individual">Individual</option>
        <option value="Entity">Entity</option>
      </SelectField>
      <TextInputField
        name="accountName"
        placeholder="Account Owner"
        marginBottom="1rem"
        width="100%"
        label="Account Owner"
        data-testid="accountName"
        onChange={onInputChange}
        value={formModel.accountName}
        disabled={isLoading || !accountNameEnabled}
        required
        isInvalid={submitCount > 0 && !formModel.accountName.length}
        validationMessage={
          submitCount > 0 && !formModel.accountName.length && 'Required'
        }
      />

      <Heading size={400}>Account address *</Heading>
      <Checkbox
        label="Same as profile"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={isLoading}
      />

      <AddressForm
        user={user}
        isFormDisabled={checked || isLoading}
        isLoading={false}
        onFormModelChange={onAddressFormModelChange}
      />

      <ButtonWithAnalytics
        analyticName="AddAccountNextBtn"
        appearance="primary"
        onClick={handleNextClick}
        isLoading={isLoading}
      >
        Next
      </ButtonWithAnalytics>
    </Pane>
  );
};
