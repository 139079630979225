/* eslint-disable arrow-body-style */
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import { Heading, Pane, TextInputField, toaster } from 'evergreen-ui';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { User, updateUser } from '@netgreen/clients';

interface UpdateIncomeFormProps {
  user: User | undefined;
  onSuccess?: () => void;
}

export const UpdateIncomeForm = ({
  user,
  onSuccess,
}: UpdateIncomeFormProps) => {
  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const { mutate: updateUserMutation, isLoading: updateUserInProgress } =
    useMutation(updateUser, {
      onSuccess: () => {
        onSuccess?.();
        toaster.success('Success', {
          description: 'Successfully updated your income limits',
        });
      },
      onError: () => {
        setErrorMessage({
          title: 'Error',
          message: 'An error occurred, please try again',
        });
      },
    });

  const {
    handleSubmit,
    handleChange: onInputChange,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      currentAnnIncome: user?.details?.currentAnnIncome,
      occupation: user?.details?.occupation,
      householdNetworth: user?.details?.householdNetworth,
    },
    onSubmit: (formValues) => {
      if (user) {
        updateUserMutation({
          id: user.id,
          properties: {
            details: {
              occupation: formValues.occupation,
              householdNetworth: formValues.householdNetworth,
              currentAnnIncome: formValues.currentAnnIncome,
            },
          },
        });
      }
    },
  });

  return (
    <Pane>
      <Pane>
        <Heading>Set your Reg CF limits</Heading>
      </Pane>
      <Pane>
        {user && (
          <form onSubmit={handleSubmit}>
            <NumericFormat
              customInput={TextInputField}
              name="currentAnnIncome"
              type="text"
              placeholder="Enter amount"
              width="100%"
              label="Yearly Income"
              disabled={false}
              allowNegative={false}
              valueIsNumericString
              onValueChange={({ floatValue }) => {
                setFieldValue('currentAnnIncome', floatValue);
              }}
              prefix="$"
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator=","
              defaultValue={user?.details?.currentAnnIncome}
            />
            <NumericFormat
              customInput={TextInputField}
              name="householdNetworth"
              placeholder="Enter amount"
              width="100%"
              label="Networth"
              disabled={false}
              valueIsNumericString
              onValueChange={({ floatValue }) => {
                setFieldValue('householdNetworth', floatValue);
              }}
              prefix="$"
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator=","
              defaultValue={user?.details?.householdNetworth}
            />
            <TextInputField
              name="occupation"
              placeholder="Enter occupation"
              width="100%"
              label="Occupation"
              onChange={onInputChange}
              disabled={false}
              defaultValue={user?.details?.occupation}
            />
            <ButtonWithAnalytics
              analyticName="updateIncomeFormSubmitBtn"
              type="submit"
              isLoading={updateUserInProgress}
              appearance="primary"
            >
              Submit
            </ButtonWithAnalytics>
          </form>
        )}
      </Pane>
    </Pane>
  );
};
