/* eslint-disable prefer-template */
import styled from '@emotion/styled';
import { User } from '@netgreen/clients';
import { breakpoints, statesAbbreviated } from '@netgreen/core-ui';
import { Heading, Pane, SelectField, TextInputField } from 'evergreen-ui';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { days, extractDob, getMonthByValue, months, years } from '../../models';

const CityStateZipContainer = styled(Pane)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;

interface ProfileFormProps {
  user: User | undefined;
  isLoading: boolean;
  onFormModelChange?: (formModel: ProfileFormModel) => void;
}

export interface ProfileFormModel {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  domicile: string;
  dobMonth: string | undefined;
  dobDate: string | undefined;
  dobYear: string | undefined;
}

const defaultModel = (user: User | undefined): ProfileFormModel => {
  const { dobMonth, dobDate, dobYear } = extractDob(user?.details?.dob);

  return {
    firstName: user?.name ?? '',
    lastName: user?.lastName ?? '',
    address: user?.details?.primAddress1 ?? '',
    address2: '',
    city: user?.details?.primCity ?? '',
    state: user?.details?.primState ?? '',
    zip: user?.details?.primZip ?? '',
    domicile: 'true',
    dobMonth: dobMonth ?? '01',
    dobDate: dobDate ?? '01',
    dobYear: dobYear ?? '1990',
  };
};

export const ProfileForm = ({
  user,
  isLoading = false,
  onFormModelChange,
}: ProfileFormProps) => {
  const [formModel, setFormModel] = useState<ProfileFormModel>(
    defaultModel(user),
  );

  const onInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { target } = event;
      const value =
        target.type === 'checkbox'
          ? (target as HTMLInputElement).checked
          : target.value;
      const { name } = target;

      setFormModel({
        ...formModel,
        [name]: value,
      });
    },
    [formModel],
  );

  useEffect(() => {
    onFormModelChange?.(formModel);
  }, [formModel, onFormModelChange]);

  return (
    <>
      <Pane display="flex" flexDirection="column">
        <TextInputField
          name="firstName"
          placeholder="First name"
          marginBottom="1rem"
          width="100%"
          label="First Name"
          onChange={onInputChange}
          value={formModel.firstName}
          disabled={isLoading}
        />
        <TextInputField
          name="lastName"
          placeholder="Last name"
          marginBottom="1rem"
          width="100%"
          label="Last Name"
          onChange={onInputChange}
          value={formModel.lastName}
          disabled={isLoading}
        />
      </Pane>

      <TextInputField
        name="address"
        placeholder="Address"
        marginBottom="1rem"
        width="100%"
        label="Address"
        onChange={onInputChange}
        value={formModel.address}
        disabled={isLoading}
      />
      <CityStateZipContainer>
        <TextInputField
          name="city"
          placeholder="City"
          marginBottom="1rem"
          width="100%"
          label="City"
          onChange={onInputChange}
          value={formModel.city}
          disabled={isLoading}
        />
        <SelectField
          name="state"
          label="State"
          width="100%"
          required
          value={formModel.state}
          onChange={onInputChange}
        >
          {statesAbbreviated.map((m) => (
            <option key={m.value} value={m.value}>
              {m.label}
            </option>
          ))}
        </SelectField>
        <TextInputField
          name="zip"
          placeholder="Zip"
          width="100%"
          label="Zip"
          onChange={onInputChange}
          value={formModel.zip}
          disabled={isLoading}
        />
      </CityStateZipContainer>
      <Pane>
        <SelectField label="Country" width="100%">
          <option value="true">United States</option>
        </SelectField>
      </Pane>

      <Heading size={400} marginTop="1rem">
        Date of birth
      </Heading>
      <Pane marginTop="1rem" display="flex" gap="1rem">
        <SelectField
          name="dobMonth"
          label="Month"
          value={formModel.dobMonth}
          onChange={onInputChange}
        >
          {Object.keys(months).map((m) => (
            <option key={m} value={months[m].monthValue}>
              {months[m].short}
            </option>
          ))}
        </SelectField>
        <SelectField
          name="dobDate"
          label="Date"
          value={Number(formModel.dobDate?.length ? formModel.dobDate : 1)}
          onChange={onInputChange}
        >
          {days(
            months[getMonthByValue(formModel.dobMonth ?? 'Jan')]?.days ?? 31,
          ).map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </SelectField>
        <SelectField
          name="dobYear"
          label="Year"
          value={formModel.dobYear}
          onChange={onInputChange}
        >
          {years().map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </SelectField>
      </Pane>
    </>
  );
};
