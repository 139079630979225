import { useTheme } from '@emotion/react';
import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { Card, Heading, Icon, Image, Pane, StarIcon, Text } from 'evergreen-ui';
import { navigate } from 'gatsby';
import React, { useCallback } from 'react';
import { AppRoutes } from '../../constants';

interface MarketplaceCardProps {
  title: string;
  subTitle?: string;
  target: number;
  image: string;
  buttonTitle: string;
  offeringId: string; // shortId
  regulationD?: boolean;
}

export const MarketplaceCard = ({
  title,
  subTitle,
  target,
  image,
  buttonTitle,
  offeringId,
  regulationD,
}: MarketplaceCardProps) => {
  const theme = useTheme();

  const navigateToLink = useCallback(() => {
    navigate(`/${AppRoutes.offering}/${offeringId}`);
  }, [offeringId]);

  return (
    <Card
      display="flex"
      flexDirection="row"
      elevation={3}
      minHeight="25rem"
      borderRadius={20}
      overflow="hidden"
      width="100%"
      data-testid={title}
    >
      <Pane width="40%">
        {image && (
          <Image
            src={image}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'left',
            }}
          />
        )}
        {!image && <Pane backgroundColor="black" width="100%" height="100%" />}
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        flex={1}
        backgroundColor={theme.background}
        justifyContent="center"
        alignItems="center"
      >
        <Heading size={800} marginBottom="1rem" textTransform="capitalize">
          {title}
        </Heading>
        <Text>Raising ${target.toLocaleString('en-US')}</Text>
        {subTitle && <Text padding="1rem" whiteSpace="pre-wrap">{subTitle}</Text>}
        <ButtonWithAnalytics
          marginTop="2rem"
          analyticName="xxx"
          borderRadius={50}
          minHeight="3rem"
          minWidth="15rem"
          fontSize="1.25rem"
          onClick={navigateToLink}
          appearance="primary"
        >
          {buttonTitle}
        </ButtonWithAnalytics>
        {regulationD && (
          <Pane marginTop="1rem" textAlign="center">
            <Pane display="flex" flexDirection="column">
              <Pane
                display="flex"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                marginBottom="1rem"
              >
                <Icon icon={StarIcon} size={12} marginRight="0.5rem" />
                <Heading size={400}>Regulation D Offering</Heading>
              </Pane>

              <Heading size={100}>Accredited Investors Only</Heading>
              <Heading size={100}>
                Offered by NetGreen Capital Investments LLC
              </Heading>
            </Pane>
          </Pane>
        )}
        {!regulationD && (
          <Pane marginTop="1rem" textAlign="center">
            <Pane display="flex" flexDirection="column">
              <Pane
                display="flex"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                marginBottom="1rem"
              >
                &nbsp;
              </Pane>

              <Heading size={100}>Open to All Investors</Heading>
              <Heading size={100}>Offered by NetGreen Capital LLC</Heading>
            </Pane>
          </Pane>
        )}
      </Pane>
    </Card>
  );
};
