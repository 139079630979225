import { RouteComponentProps } from '@reach/router';
import { Pane, Spinner } from 'evergreen-ui';
import { navigate } from 'gatsby';
import React, { ComponentType } from 'react';
import { useIsLoggedIn } from '../auth';

interface AuthenticatedRouteProps {
  component: ComponentType<RouteComponentProps>;
}
export const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: AuthenticatedRouteProps & RouteComponentProps) => {
  const { isLoggedIn, isLoading } = useIsLoggedIn();

  if (!isLoading && !isLoggedIn && window.location.pathname !== '/login') {
    navigate('/login');
    return null;
  }

  if (isLoading) {
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={400}
      >
        <Spinner />
      </Pane>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...rest} />;
};
