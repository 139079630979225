/* eslint-disable @typescript-eslint/indent */
import styled from '@emotion/styled';
import {
  BaseContent,
  ButtonWithAnalytics,
  PageContent,
  breakpoints,
} from '@netgreen/core-ui';
import { useQuery } from '@tanstack/react-query';
import {
  AddIcon,
  Pane,
  Spinner,
  Table,
  TextTableCell,
  TextTableHeaderCell,
  TickCircleIcon,
} from 'evergreen-ui';
import { navigate } from 'gatsby';
import React from 'react';
import { GetAccountResponse, getAccounts } from '@netgreen/clients';
import { AppRoutes, DashboardRoutes } from '../../constants';

const StyledBaseContent = styled(BaseContent)`
  @media (min-width: ${breakpoints.tablet}px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
export const Accounts = () => {
  const { data: accountsData, isLoading: accountsLoading } = useQuery<
    GetAccountResponse[]
  >(['current-user-accounts'], () => getAccounts(), {
    cacheTime: 60 * 60 * 1000, // 1 hour
  });

  return (
    <StyledBaseContent>
      <PageContent pageTitle="Accounts" noXMargin>
        <Table marginBottom="1rem">
          <Table.Head>
            <TextTableHeaderCell>Account Name</TextTableHeaderCell>
            <TextTableHeaderCell>Account Number</TextTableHeaderCell>
            <TextTableHeaderCell>Status</TextTableHeaderCell>
          </Table.Head>
          <Table.Body>
            {accountsLoading && (
              <Pane display="flex" justifyContent=" center" marginY="2rem">
                <Spinner />
              </Pane>
            )}
            {!accountsLoading && (
              <>
                {accountsData?.map((account) => (
                  <Table.Row key={account.createdDate}>
                    <TextTableCell>
                      <Pane>{account.accountName}</Pane>
                      <Pane>{account.accountNickName}</Pane>
                    </TextTableCell>
                    <TextTableCell>
                      ****{' '}
                      {account.accountNumber?.slice(
                        account.accountNumber.length - 5,
                        account.accountNumber.length - 1,
                      )}
                    </TextTableCell>
                    <TextTableCell>
                      {account.accountSource === 'pending' ? (
                        <TickCircleIcon color="warning" marginRight={16} />
                      ) : (
                        <TickCircleIcon color="success" marginRight={16} />
                      )}
                    </TextTableCell>
                  </Table.Row>
                ))}
                {accountsData?.length === 0 && (
                  <Table.Row>
                    <Table.Cell>
                      No bank accounts or cards have been linked.
                    </Table.Cell>
                  </Table.Row>
                )}
              </>
            )}
          </Table.Body>
        </Table>
        <ButtonWithAnalytics
          analyticName="AddPaymentBtn"
          iconBefore={AddIcon}
          appearance="primary"
          width="100%"
          onClick={() => {
            navigate(`/${AppRoutes.dashboard}/${DashboardRoutes.addAccount}`);
          }}
        >
          Add a payment method
        </ButtonWithAnalytics>
      </PageContent>
    </StyledBaseContent>
  );
};
