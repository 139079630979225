import { BaseClient } from '../../base-client';
import { Trade, TradeMetadata } from '../models';

interface ITradeClient {
  createTrade: (
    offeringId: string,
    accountId: string,
    transactionUnits: string,
    otherRegCF: number,
  ) => Promise<Trade>;
  getTrade: (tradeId: string) => Promise<Trade>;
  cancelTrade: (tradeId: string) => Promise<void>;
}

class TradeClient extends BaseClient implements ITradeClient {
  constructor(private baseUrl: string) {
    super(baseUrl);
  }

  async createTrade(
    offeringId: string,
    accountId: string,
    transactionUnits: string,
    otherRegCF: number,
  ): Promise<Trade> {
    if (offeringId === 'undefined') {
      throw new Error('empty offering id');
    }
    const authHeaders = await this.getAuthHeaders();
    // TODO: Figure out how to get transaction type
    return (
      await this.httpClient.post(
        '/v1/trade',
        {
          offeringId,
          transactionUnits,
          transactionType: 'ACH',
          accountId,
          otherRegCF,
        },
        {
          headers: { ...authHeaders },
        },
      )
    ).data;
  }

  async getTrade(tradeId: string): Promise<Trade> {
    const authHeaders = await this.getAuthHeaders();
    return (
      await this.httpClient.get(`/v1/trade/${tradeId}`, {
        headers: { ...authHeaders },
      })
    ).data;
  }

  async getTradeOfferingMetadata(offeringId: string): Promise<TradeMetadata> {
    return (await this.httpClient.get(`/v1/offering/${offeringId}/meta`)).data;
  }

  async getUserTrades(): Promise<Trade[]> {
    const authHeaders = await this.getAuthHeaders();
    return (
      await this.httpClient.get('/v1/trades', {
        headers: { ...authHeaders },
      })
    ).data;
  }

  async cancelTrade(tradeId: string): Promise<void> {
    const authHeaders = await this.getAuthHeaders();
    await this.httpClient.delete(`/v1/trade/${tradeId}`, {
      headers: { ...authHeaders },
    });
  }
}

export const getTradeClient = (baseUrl: string) => new TradeClient(baseUrl);
