/* eslint-disable operator-linebreak */
import { EnvelopeIcon, Icon, Image } from 'evergreen-ui';
import React from 'react';
import Facebook from '../../images/facebook.png';
import FacebookDark from '../../images/facebook_dark.png';
import Instagram from '../../images/instagram.png';

import InstagramDark from '../../images/instagram_dark.png';
import X from '../../images/x.png';
import XDark from '../../images/x_dark.png';

type SocialPlatform = 'facebook' | 'twitter' | 'instagram' | 'email';

interface SocialLink {
  name: SocialPlatform;
  link: string;
}

interface SocialLinksProps {
  socialConfig: SocialLink[];
  iconTheme?: 'dark' | 'light';
  iconHeight?: string;
}

const socialImagesLight: { [K in SocialPlatform]: string } = {
  facebook: Facebook,
  twitter: X,
  instagram: Instagram,
  email: 'email',
};

const socialImagesDark: {
  [K in SocialPlatform]: string;
} = {
  facebook: FacebookDark,
  twitter: XDark,
  instagram: InstagramDark,
  email: 'email',
};

export const SocialLinks = ({
  socialConfig,
  iconTheme,
  iconHeight,
}: SocialLinksProps) => (
  <>
    {socialConfig.map((social) => {
      if (!social.link.length) {
        return undefined;
      }
      const image: string =
        iconTheme === 'dark'
          ? socialImagesDark[social.name]
          : socialImagesLight[social.name];
      return (
        <a key={social.name} href={social.link}>
          {social.name === 'email' ? (
            <Icon
              icon={EnvelopeIcon}
              color={iconTheme === 'dark' ? 'black' : 'white'}
              height={iconHeight ?? '2rem'}
              size={48}
            />
          ) : (
            <Image
              src={image}
              alt={social.name}
              height={iconHeight ?? '2rem'}
              marginRight="0.75rem"
            />
          )}
        </a>
      );
    })}
  </>
);
