import { Amplify } from '@aws-amplify/core';
import { environment } from '../environments/environment';

export const setupAmplify = () => {
  Amplify.configure({
    Auth: {
      region: environment.region,
      userPoolId: environment.userPoolId,
      userPoolWebClientId: environment.userPoolWebClientId,
      identityPoolId: environment.identityPoolId,
    },
    Storage: {
      AWSS3: {
        region: environment.region,
        bucket: environment.bucket,
      },
    },
  });
};
