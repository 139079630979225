import { Dict, init, Mixpanel } from "mixpanel-browser";
import { environment } from "../environments/environment";
import { User } from "./models";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

class AnalyticsService {
  mixpanel: Mixpanel;

  constructor() {
    this.mixpanel = init(environment.MIXPANEL_KEY!, {}, "netgreen");
  }

  trackEventWithProperties = (name: string, attributes: Dict): void => {
    if (navigator.doNotTrack === "1") {
      return;
    }
    this.mixpanel.track(name, attributes);
    if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
      window.gtag("event", name, attributes);
    }
  };
  trackCharge = (amount: number, attributes?: Dict): void => {
    if (navigator.doNotTrack === "1") {
      return;
    }
    this.mixpanel.people.track_charge(amount, attributes);
  };

  register = (user: Partial<User>): void => {
    if (navigator.doNotTrack === "1") {
      return;
    }
    this.mixpanel.register_once({
      id: user.id,
      email: user.email,
      name: user.name,
      lastName: user.lastName,
    });
    if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
      window.gtag("event", "login", user);
    }
  };
}

export const Analytics = new AnalyticsService();
