import { LocationProvider } from '@reach/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { ThemedApp } from '@netgreen/theme';
import { BasePage } from '../components';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 0,
    },
    queries: {
      retry: 0,
    },
  },
});

export const Layout = ({ children }: { children: any }) => (
  <LocationProvider>
    <ThemedApp>
      <QueryClientProvider client={queryClient}>
        <BasePage>{children}</BasePage>
      </QueryClientProvider>
    </ThemedApp>
  </LocationProvider>
);

export default Layout;
