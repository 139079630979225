export const AppRoutes = {
  signUp: 'create-account',
  login: 'login',
  contact: 'contact',
  offering: 'offering',
  confirmSignup: 'confirm-signup',
  confirmForgotPassword: 'confirm-forgot-password',
  forgotPassword: 'forgot',
  dashboard: 'dashboard',
  farmStand: 'farmstand',
  education: 'education',
  marketplace: 'marketplace',
  faq: 'faq',
  about: 'about',
  community: 'community',
};

export const DashboardRoutes = {
  profile: 'profile',
  accountSetup: 'account-setup',
  accounts: 'accounts',
  addAccount: 'add-account',
  portfolio: 'portfolio',
  investorLimits: 'investor-limits',
  updateIncome: 'update-income',
};
