import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import {
  CodeMismatchError,
  ExpiredCodeError,
  ForgotPasswordSignInError,
  LimitExceededError,
  NotAuthorizedError,
  PasswordPolicyError,
  UsernameExistsError,
} from './authErrors';

export interface CognitoUser {
  sub: string;
  email_verified: boolean;
  name: string;
  family_name: string;
  email: string;
  username: string;
  phone_number: string;
}

interface SignUpParams {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

interface SignInParams {
  password: string;
  email: string;
}

// Turn aws errors into netgreen UI safe to display errors
const handleError = (error: unknown) => {
  const exceptionName = (error as Error).name;
  const exceptionMessage = (error as Error).message;

  switch (exceptionName) {
    case 'InvalidPasswordException':
      throw new PasswordPolicyError();
    case 'UsernameExistsException':
      throw new UsernameExistsError();
    case 'ExpiredCodeException':
      throw new ExpiredCodeError();
    case 'CodeMismatchException':
      throw new CodeMismatchError();
    case 'LimitExceededException':
      throw new LimitExceededError();
    case 'NotAuthorizedException':
      throw new NotAuthorizedError(exceptionMessage);
    default:
  }
};

export const signUp = async (
  params: SignUpParams,
): Promise<{ username: string }> => {
  try {
    const { user } = await Auth.signUp({
      username: params.email,
      password: params.password,
      attributes: {
        email: params.email,
        name: params.firstName,
        family_name: params.lastName,
      },
      autoSignIn: {
        // enables auto sign in after user is confirmed
        enabled: true,
      },
    });
    return { username: user.getUsername() };
  } catch (error: unknown) {
    handleError(error);

    throw error;
  }
};

interface ConfirmSignUpParams {
  email: string;
  code: string;
}
export const confirmSignUp = async ({ email, code }: ConfirmSignUpParams) => {
  try {
    await Auth.confirmSignUp(email, code);
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const resendConfirmationCode = async (username: string) => {
  try {
    await Auth.resendSignUp(username);
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const signIn = async ({
  email,
  password,
}: SignInParams): Promise<void> => {
  try {
    const user = await Auth.signIn(email, password);
    await Auth.currentAuthenticatedUser();
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const signOutGlobal = async () => {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    handleError(error);
    throw error;
  }
};
interface ForgotPasswordParams {
  email: string;
}

export const forgotPassword = async ({ email }: ForgotPasswordParams) => {
  try {
    await Auth.forgotPassword(email);
  } catch (error) {
    handleError(error);
    throw error;
  }
};

interface ForgotPasswordSubmitParams {
  email: string;
  code: string;
  newPassword: string;
}
export const forgotPasswordSubmit = async ({
  email,
  code,
  newPassword,
}: ForgotPasswordSubmitParams) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    try {
      await signIn({ email, password: newPassword });
    } catch (err) {
      throw new ForgotPasswordSignInError();
    }
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const getToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const getCognitoUser = async (): Promise<CognitoUser | undefined> => {
  try {
    const { username, attributes } = await Auth.currentAuthenticatedUser();
    return { ...attributes, username } as CognitoUser;
  } catch (error) {
    return undefined;
  }
};

export const isLoggedIn = async (): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const verifyPhoneNumber = async () => {
  try {
    await Auth.verifyCurrentUserAttribute('phone_number');
  } catch (error) {
    handleError(error);
  }
};

export const updatePhoneNumber = async (phoneNumber: string): Promise<void> => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      phone_number: phoneNumber,
    });
    await verifyPhoneNumber();
  } catch (error) {
    handleError(error);
  }
};

/**
 * @description Method to verify attributes that require a OTP after account registration
 * @param attributeName - Name of the cognito attribute
 * @param code - OTP code
 */
export const verifyCurrentUserAttributeSubmit = async (
  attributeName: string,
  code: string,
) => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit(attributeName, code);
  } catch (error) {
    handleError(error);
  }
};

function listenToAutoSignInEvent() {
  Hub.listen('auth', ({ payload }) => {
    const { event } = payload;
    if (event === 'autoSignIn') {
      const user = payload.data;
      console.log('autoSignin ', user);
      // assign user
    } else if (event === 'autoSignIn_failure') {
      // redirect to sign in page
      console.log('autoSigninFail ');
    }
  });
}

listenToAutoSignInEvent();
