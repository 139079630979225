export const months: Record<
  string,
  { short: string; days: number; monthValue: string }
> = {
  Jan: { short: 'Jan', days: 31, monthValue: '01' },
  Feb: { short: 'Feb', days: 29, monthValue: '02' },
  March: { short: 'March', days: 31, monthValue: '03' },
  April: { short: 'April', days: 30, monthValue: '04' },
  May: { short: 'May', days: 31, monthValue: '05' },
  June: { short: 'June', days: 30, monthValue: '06' },
  July: { short: 'July', days: 31, monthValue: '07' },
  Aug: { short: 'Aug', days: 31, monthValue: '08' },
  Sep: { short: 'Sep', days: 30, monthValue: '09' },
  Oct: { short: 'Oct', days: 31, monthValue: '10' },
  Nov: { short: 'Nov', days: 31, monthValue: '11' },
  Dec: { short: 'Dec', days: 31, monthValue: '12' },
};

export const days = (numDays: number) =>
  Array.from(Array(numDays).keys()).map((num) => num + 1);

export const years = () => {
  const currentYear = new Date().getFullYear();

  const yearsArr = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 18; i < 100; i++) {
    yearsArr.push(currentYear - i);
  }

  return yearsArr;
};

export const getMonthByValue = (monthValue: string) => {
  const res = Object.keys(months).find(
    (v) => months[v].monthValue === monthValue,
  );

  return res ?? 'Jan';
};

export const extractDob = (dobString: string | undefined) => {
  if (!dobString) {
    return { dobMonth: undefined, dobDate: undefined, dobYear: undefined };
  }
  const arr = dobString.split('-');

  return { dobMonth: arr[0], dobDate: arr[1], dobYear: arr[2] };
};

const addLeadingZero = (number: string) => {
  const num = Number(number);
  return num < 10 ? `0${num}` : num;
};

export const getFormattedDob = (month: string, date: string, year: string) =>
  `${month}-${addLeadingZero(date)}-${year}`;
