import { ButtonWithAnalyticsWithRef } from '@netgreen/core-ui';
import {
  Avatar,
  Icon,
  Menu,
  Pane,
  Popover,
  Position,
  UserIcon,
} from 'evergreen-ui';
import { navigate } from 'gatsby';
import React from 'react';
import { AppRoutes } from '../../constants';
import { useCurrentUser } from '../auth';

interface ProfileDropdownButtonProps {
  logout: () => void;
}

export const ProfileDropdownButton = ({
  logout,
}: ProfileDropdownButtonProps) => {
  const { data: user } = useCurrentUser();
  return (
    <Pane>
      <Popover
        position={Position.BOTTOM_LEFT}
        // eslint-disable-next-line react/no-unstable-nested-components
        content={({ close }) => (
          <Menu>
            <Menu.Group>
              <Menu.Item
                onSelect={() => {
                  close();
                  navigate(`/${AppRoutes.dashboard}`);
                }}
              >
                Dashboard
              </Menu.Item>
            </Menu.Group>
            <Menu.Divider />
            <Menu.Group>
              <Menu.Item
                onSelect={() => {
                  logout();
                  close();
                }}
              >
                Logout
              </Menu.Item>
            </Menu.Group>
          </Menu>
        )}
      >
        {({ getRef, isShown, toggle }) => (
          <ButtonWithAnalyticsWithRef
            analyticName="NavProfileDropdownBtn"
            border="none"
            borderRadius="50%"
            height="unset"
            padding={0}
            onClick={toggle}
            ref={getRef as any}
            isActive={isShown}
          >
            {user ? (
              <Avatar name={`${user?.name} ${user?.lastName}`} size={50} />
            ) : (
              <Icon icon={UserIcon} size={48} />
            )}
          </ButtonWithAnalyticsWithRef>
        )}
      </Popover>
    </Pane>
  );
};
