/* eslint-disable react/jsx-props-no-spreading */
import { Pane, PaneProps } from "evergreen-ui";
import { desktopMaxContentWidth } from "../breakpoints";

export const BaseContent = ({ children, ...props }: PaneProps) => (
  <Pane
    maxWidth={desktopMaxContentWidth}
    display="flex"
    margin="auto"
    flexDirection="column"
    {...props}
  >
    {children}
  </Pane>
);
