import { Avatar, Icon, Pane, Text, UserIcon } from 'evergreen-ui';
import { navigate } from 'gatsby';
import React from 'react';
import { AppRoutes } from '../../constants';
import { useCurrentUser } from '../auth';

interface ProfileRowProps {
  showNav: (show: boolean) => void;
}
export const ProfileRow = ({ showNav }: ProfileRowProps) => {
  const { data: user } = useCurrentUser();
  const name = `${user?.name} ${user?.lastName}`;
  return (
    <Pane
      display="flex"
      alignItems="center"
      flexDirection="row"
      onClick={() => {
        navigate(`/${AppRoutes.dashboard}`);
        showNav(false);
      }}
    >
      {user ? (
        <Avatar name={name} size={50} />
      ) : (
        <Icon icon={UserIcon} size={48} />
      )}

      <Pane marginLeft="1rem" display="flex" flexDirection="column">
        <Text fontWeight="bold">{name}</Text>
      </Pane>
    </Pane>
  );
};
