/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { AddAccountResponse, addAccount } from '@netgreen/clients';
import { breakpoints } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import { Alert, Pane, Spinner } from 'evergreen-ui';
import React, { useCallback, useState } from 'react';
import { useCurrentUser } from '../../components/auth/useCurrentUser';
import {
  AccountFormModel,
  AddAccountOptions,
  AddAccountSuccess,
  ConfirmAddress,
  ManualAccount,
} from './add-account';
import { AddAccountState } from './models';

const Container = styled(Pane)`
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 45rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

interface AddAccountFlowProps {
  onStateChange?: (state: AddAccountState) => void;
}

export const AddAccountFlow = ({ onStateChange }: AddAccountFlowProps) => {
  const { data: user, isLoading: isLoadingUser } = useCurrentUser();
  const [state, setState] = useState(AddAccountState.confirmAddress);
  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);
  const [addAccountResponse, setAddAccountResponse] = useState<
    AddAccountResponse | undefined
  >(undefined);
  const { mutate: addAccountMutation, isLoading: addAccountInProgress } =
    useMutation(addAccount, {
      onSuccess: (data) => {
        setAddAccountResponse(data);
        setState(AddAccountState.addAccountOptions);
        onStateChange?.(AddAccountState.addAccountOptions);
      },
      onError: (error: Error) => {
        setErrorMessage({
          title: 'Error',
          message: 'An unknown error occurred',
        });
      },
    });

  const onConfirmAddress = useCallback(
    (model: AccountFormModel) => {
      if (!user) {
        return;
      }

      setErrorMessage(undefined);

      addAccountMutation({
        accountName: model.accountName,
        city: model.city,
        country: 'United States',
        state: model.state,
        streetAddress1: model.address,
        type: model.type,
        zip: model.zip,
      });
    },
    [addAccountMutation, user],
  );

  const onAddAccountDetailsSuccess = useCallback(() => {
    setState(AddAccountState.addAccountSuccess);
  }, []);

  const onChangeState = useCallback(
    (newState: AddAccountState) => {
      setState(newState);
      onStateChange?.(newState);
    },
    [onStateChange],
  );

  if (isLoadingUser || !user) {
    return (
      <Pane display="flex" justifyContent="center" margin="auto" height="100%">
        <Spinner />
      </Pane>
    );
  }

  return (
    <>
      {errorMessage && (
        <Pane marginBottom="1rem">
          <Alert title={errorMessage.title} intent="danger">
            {errorMessage.message}
          </Alert>
        </Pane>
      )}
      {state === AddAccountState.confirmAddress && (
        <ConfirmAddress
          onNext={onConfirmAddress}
          user={user}
          isLoading={addAccountInProgress}
        />
      )}
      {state === AddAccountState.addAccountOptions && (
        <AddAccountOptions
          accountId={addAccountResponse?.accountId}
          onSuccess={onAddAccountDetailsSuccess}
          changeState={onChangeState}
        />
      )}
      {state === AddAccountState.addAccountSuccess && <AddAccountSuccess />}

      {state === AddAccountState.addManualAccount &&
        addAccountResponse?.accountId && (
          <ManualAccount
            accountId={addAccountResponse.accountId}
            onSuccess={onAddAccountDetailsSuccess}
          />
        )}
    </>
  );
};
