import styled from '@emotion/styled';
import { breakpoints } from '@netgreen/core-ui';
import {
  Image,
  Link,
  Pane,
  Paragraph,
  Strong,
  Text,
  useTheme,
} from 'evergreen-ui';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { AppRoutes } from '../../constants';
import Facebook from '../../images/facebook.png';
import Instagram from '../../images/instagram.png';
import X from '../../images/x.png';
import { LinkConfig } from '../../models';
import { MarkdownContent } from '../markdown-content';

const Divider = styled.hr`
  background-color: rgba(255, 255, 255, 0.1) !important;
  width: 100%;
  max-width: 50rem;
`;

const linkConfigLeft: LinkConfig[] = [
  {
    name: 'About us',
    to: `/${AppRoutes.about}`,
  },
  {
    name: 'News & Podcasts',
    to: '/resources',
  },
  {
    name: 'FAQs',
    to: `/${AppRoutes.faq}`,
  },
  {
    name: 'Contact us',
    to: '/contact',
  },
];

const linkConfigCenter: LinkConfig[] = [
  {
    name: 'Marketplace',
    to: `/${AppRoutes.marketplace}`,
  },
  {
    name: 'Farm Stand',
    to: `/${AppRoutes.farmStand}`,
  },
  {
    name: 'Community',
    to: `/${AppRoutes.community}`,
  },
  {
    name: 'Apply for Fundraising',
    to: '/apply',
  },
];

const linkConfigRight: LinkConfig[] = [
  {
    name: 'Educational Materials',
    to: `/${AppRoutes.education}`,
  },
  {
    name: 'Terms of Service',
    to: '/legal/terms',
  },
  {
    name: 'Privacy Policy',
    to: '/legal/privacy',
  },
];

const LinkContainer = styled(Pane)`
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }
`;

const SocialContainer = styled(Pane)`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: auto;
  }
`;

interface SocialProps {
  name: string;
  link: string;
}

interface LegalContent {
  data: Record<string, string>;
}

interface LegalNode {
  page: string;
  content: LegalContent;
}

export const Footer = () => {
  const theme = useTheme();
  const year = new Date().getFullYear();

  const strapiQueries = useStaticQuery(graphql`
    query disclosureQueryAndSocialMediaLinks {
      strapiLegal(page: { eq: "footer-disclosure" }) {
        content {
          data {
            content
          }
        }
      }
      allStrapiSocial {
        nodes {
          link
          name
        }
      }
    }
  `);

  return (
    <footer>
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        backgroundColor={theme.colors.blue800}
        paddingX="1rem"
        paddingY="2rem"
      >
        <LinkContainer>
          <Pane display="flex" flexDirection="column" marginRight="1rem">
            {linkConfigLeft.map((link) => (
              <Link
                key={link.name}
                is={GatsbyLink}
                to={link.to}
                marginY="0.25rem"
                color={theme.colors.white}
              >
                {link.name}
              </Link>
            ))}
          </Pane>
          <Pane display="flex" flexDirection="column" marginRight="1rem">
            {linkConfigCenter.map((link) => (
              <Link
                key={link.name}
                is={GatsbyLink}
                to={link.to}
                marginY="0.25rem"
                color={theme.colors.white}
              >
                {link.name}
              </Link>
            ))}
          </Pane>
          <Pane display="flex" flexDirection="column" marginRight="1rem">
            {linkConfigRight.map((link) => (
              <Link
                key={link.name}
                is={GatsbyLink}
                to={link.to}
                marginY="0.25rem"
                color={theme.colors.white}
              >
                {link.name}
              </Link>
            ))}
          </Pane>

          <Pane marginY="auto">
            <SocialContainer>
              {(strapiQueries?.allStrapiSocial?.nodes as SocialProps[]).map(
                (social) => {
                  const socialImages: { [key: string]: string } = {
                    facebook: Facebook,
                    twitter: X,
                    instagram: Instagram,
                  };

                  const image: string = socialImages[social.name];
                  return (
                    <Link
                      key={social.name}
                      href={social.link}
                      marginY="0.25rem"
                      color={theme.colors.white}
                      target="_blank"
                    >
                      <Image
                        src={image}
                        alt={social.name}
                        height="2rem"
                        marginRight="0.75rem"
                      />
                    </Link>
                  );
                },
              )}
            </SocialContainer>
          </Pane>
        </LinkContainer>

        <Pane>
          <Divider />
        </Pane>
        <Pane
          display="flex"
          flexDirection="column"
          maxWidth="50rem"
          marginX="auto"
        >
          <Pane marginTop="2rem">
            <Paragraph color={theme.colors.white} size={300}>
              <MarkdownContent
                content={
                  (strapiQueries.strapiLegal as LegalNode).content.data.content
                }
              />
            </Paragraph>
          </Pane>
          <Pane
            justifyContent="center"
            marginTop="1rem"
            marginBottom="2rem"
            display="flex"
            maxWidth="50rem"
          >
            <Strong color={theme.colors.white}>
              ALL OF THE INVESTMENT OPPORTUNITIES ON NETGREEN CAPITAL CONTAIN
              RISK. ONLY INVEST IF YOU CAN AFFORD TO LOSE YOUR ENTIRE
              INVESTMENT.
            </Strong>
          </Pane>
          <Pane display="flex">
            <Text color={theme.colors.white}>
              {`© ${year} NetGreen Capital LLC`}
            </Text>
          </Pane>
        </Pane>
      </Pane>
    </footer>
  );
};
